import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Body1Regular, Body3Light, Body3Regular, Subtitle1Regular } from '../../lib/font';
import GenderDropDown from '../DropDown/GenderDropDown';
import NormalButton from '../Button/NormalButton';
import { I_GREY_CIRCLE } from '../../types/images';
import RoundedDropDown from '../DropDown/RoundedDropDown';
import ColorRoundButton from '../Button/ColorRoundButton';
import WhiteRoundButton from '../Button/WhiteRoundButton';

type Props = {
    dateBirth: string;
    selectedGender: any;
    email: string;
    phoneNumber: string;
    dateJoinCompany: string;
    position: string;
    setSelectedGender: any;
    onChangeDateBirth: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangePhoneNumber: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangeDateJoinCompany: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangePosition: (e: ChangeEvent<HTMLInputElement>) => void;
    onClickMemberInfo: () => void;
    onClickSave: () => void;
    changeFlag: boolean;
    onClickClassHistory: () => void;
    isConnected: boolean;
    isDeleted: boolean;
    isShareOnetimeSchedule: boolean;
    authObject: any;
    isOpenGender: boolean;
    setIsOpenGender: Dispatch<SetStateAction<boolean>>;
    onClickDeleteButton: () => void;
};

let genderOptions: any = [
    { value: '', label: '선택' },
    { value: 'male', label: '남성' },
    { value: 'female', label: '여성' },
];

const DetailTopRight = ({
    dateBirth,
    selectedGender,
    email,
    phoneNumber,
    dateJoinCompany,
    position,
    setSelectedGender,
    onChangeDateBirth,
    onChangeEmail,
    onChangePhoneNumber,
    onChangeDateJoinCompany,
    onChangePosition,
    onClickMemberInfo,
    onClickSave,
    changeFlag,
    onClickClassHistory,
    isConnected,
    isDeleted,
    isShareOnetimeSchedule,
    authObject,
    isOpenGender,
    setIsOpenGender,
    onClickDeleteButton,
}: Props) => {
    // 성별 드롭다운 함수
    const toggleGenderDropDown = () => {
        setIsOpenGender(!isOpenGender);
    };

    // 성별 선택 함수
    const handleGenderSelect = (option: any) => {
        setSelectedGender(option);
        setIsOpenGender(false);
    };

    return (
        <Col
            style={{
                width: 787,
                height: 476,
                borderRadius: 16,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #C3D4F4',
                paddingLeft: 56,
                paddingTop: 56,
            }}
        >
            <Row style={{ alignItems: 'center' }}>
                <Col style={{ width: 300, height: 68 }}>
                    <Body3Regular style={{ color: colors.LAVEL_4, marginBottom: 8 }}>생년월일</Body3Regular>

                    <input
                        name="dateBirth"
                        id="dateBirth"
                        value={dateBirth}
                        placeholder="생일을 입력해주세요! "
                        onChange={onChangeDateBirth}
                        readOnly={
                            authObject?.testAccount ? true : isConnected === true && isDeleted === false ? false : true
                        }
                        style={{
                            width: 300,
                            height: 36,
                            borderRadius: 36,
                            border: '1px solid #D4DBE8',
                            paddingLeft: 16,
                            fontSize: 16,
                            fontWeight: 400,
                        }}
                    />
                </Col>

                <Col style={{ height: 68, marginLeft: 48 }}>
                    <Body3Regular style={{ color: colors.LAVEL_4, marginBottom: 8 }}>성별</Body3Regular>

                    <RoundedDropDown
                        toggleSortDropDown={toggleGenderDropDown}
                        sortType={selectedGender}
                        isOpenSort={isOpenGender}
                        sortOptions={genderOptions}
                        handleSortSelect={handleGenderSelect}
                        size="small2"
                    />
                </Col>
            </Row>

            <Row style={{ alignItems: 'center', marginTop: 48 }}>
                <Col style={{ width: 300, height: 68 }}>
                    <Body3Regular style={{ color: colors.LAVEL_4, marginBottom: 8 }}>휴대폰 번호</Body3Regular>
                    <input
                        name="phoneNumber"
                        id="phoneNumber"
                        value={phoneNumber}
                        placeholder="휴대폰 번호를 입력해주세요!"
                        onChange={onChangePhoneNumber}
                        readOnly={
                            authObject?.testAccount ? true : isConnected === true && isDeleted === false ? false : true
                        }
                        style={{
                            width: 300,
                            height: 36,
                            borderRadius: 36,
                            border: '1px solid #D4DBE8',
                            paddingLeft: 16,
                            fontSize: 16,
                            fontWeight: 400,
                        }}
                    />
                </Col>

                <Col style={{ width: 300, height: 68, marginLeft: 48 }}>
                    <Body3Regular style={{ color: colors.LAVEL_4, marginBottom: 8 }}>직급</Body3Regular>

                    <input
                        name="position"
                        id="position"
                        value={position}
                        maxLength={10}
                        placeholder="ex) 팀장"
                        onChange={onChangePosition}
                        readOnly={
                            authObject?.testAccount ? true : isConnected === true && isDeleted === false ? false : true
                        }
                        style={{
                            width: 192,
                            height: 36,
                            borderRadius: 36,
                            border: '1px solid #D4DBE8',
                            paddingLeft: 16,
                            fontSize: 16,
                            fontWeight: 400,
                        }}
                    />
                </Col>
            </Row>

            <Row style={{ alignItems: 'center', marginTop: 48 }}>
                <Col style={{ width: 300, height: 68 }}>
                    <Body3Regular style={{ color: colors.LAVEL_4, marginBottom: 8 }}>이메일</Body3Regular>
                    <input
                        name="email"
                        id="email"
                        value={email}
                        maxLength={40}
                        onChange={onChangeEmail}
                        placeholder="이메일을 입력해주세요!"
                        readOnly={
                            authObject?.testAccount ? true : isConnected === true && isDeleted === false ? false : true
                        }
                        style={{
                            width: 300,
                            height: 36,
                            borderRadius: 36,
                            border: '1px solid #D4DBE8',
                            paddingLeft: 16,
                            fontSize: 16,
                            fontWeight: 400,
                        }}
                    />
                </Col>

                <Col style={{ width: 300, height: 68, marginLeft: 48 }}>
                    {' '}
                    <Body3Regular style={{ color: colors.LAVEL_4, marginBottom: 8 }}>입사일</Body3Regular>
                    <input
                        name="dateJoinCompany"
                        id="dateJoinCompany"
                        value={dateJoinCompany}
                        placeholder="0000-00-00"
                        onChange={onChangeDateJoinCompany}
                        readOnly={
                            authObject?.testAccount ? true : isConnected === true && isDeleted === false ? false : true
                        }
                        style={{
                            width: 192,
                            height: 36,
                            borderRadius: 36,
                            border: '1px solid #D4DBE8',
                            paddingLeft: 16,
                            fontSize: 16,
                            fontWeight: 400,
                        }}
                    />
                </Col>
            </Row>

            <Row style={{ marginTop: 64, alignItems: 'center' }}>
                <WhiteRoundButton
                    text="강사 삭제"
                    size="tiny"
                    customStyle={{
                        width: 96,
                        height: 24,
                        marginRight: 475,
                    }}
                    callBack={onClickDeleteButton}
                />

                {isConnected === true && isDeleted === false && (
                    <ColorRoundButton
                        isDisabled={!changeFlag || authObject?.testAccount}
                        text={'정보 변경하기'}
                        callBack={onClickSave}
                        size="tiny"
                        customStyle={{ width: 112, height: 24 }}
                    />
                )}

                {/* <Row style={{ alignItems: 'center' }}>
                    <NormalButton text={'수업 히스토리'} style={{ marginLeft: 60 }} onClick={onClickClassHistory} />
                    <NormalButton text={'담당 회원정보'} style={{ marginLeft: 36 }} onClick={onClickMemberInfo} />
                </Row>

             
                {isConnected === true && isDeleted === false && (
                    <NormalButton
                        disabled={!changeFlag || authObject?.testAccount}
                        text={'저장하기'}
                        onClick={onClickSave}
                        style={{ marginRight: 60 }}
                    />
                )} */}
            </Row>
        </Col>
    );
};

export default DetailTopRight;
