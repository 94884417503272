import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Col, Row, getCurrentDayWeekNumber } from '../../../lib/utils';
import MemberMealListGraphHeader from './MemberMealListGraphHeader';
import { colors } from '../../../lib/colors';
import { useQuery } from '@tanstack/react-query';
import { getTrainerMemberMealCountAPI } from '../../../api/trainer';
import MemberMealListDailyGraph from './MemberMealListDailyGraph';
import MemberMealListWeeklyGraph from './MemberMealListWeeklyGraph';
import MemberMealListMonthlyGraph from './MemberMealListMonthlyGraph';
import { Body2Regular } from '../../../lib/font';
import {
    dailyDummyData,
    dailyMaxCountDummyData,
    monthlyDummyData,
    monthlyMaxCountDummyData,
    weeklyDummyData,
    weeklyMaxCountDummyData,
} from './DummyData';

type Props = {
    authorization: any;
    employeeId: string;
    authObject: any;
};

const MemberMealListGraph = ({ authorization, employeeId, authObject }: Props) => {
    // 초기값 설정
    const [currentDate, setCurrentDate] = useState(dayjs());
    // 당일
    const [dailyEntireData, setDailyEntireData] = useState<any>();
    const [dailyFilterData, setDailyFilterData] = useState<any>();
    const [dailyMaxCount, setDailyMaxCount] = useState<number>(0);
    // 주간
    const [weeklyEntireData, setWeeklyEntireData] = useState<any>();
    const [weeklyFilterData, setWeeklyFilterData] = useState<any>();
    const [weeklyMaxCount, setWeeklyMaxCount] = useState<number>(0);
    // 월간
    const [monthlyEntireData, setMonthlyEntireData] = useState<any>();
    const [monthlyFilterData, setMonthlyFilterData] = useState<any>();
    const [monthlyMaxCount, setMonthlyMaxCount] = useState<number>(0);

    const [isToday, setIsToday] = useState<boolean>(true);
    const [isWeek, setIsWeek] = useState<boolean>(false);
    const [isMonth, setIsMonth] = useState<boolean>(false);

    // 몇주차 표시
    const [weekNumber, setWeekNumber] = useState<number>(0);

    const onClickAddDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).add(1, 'week');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).add(1, 'month');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).add(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const onClickSubtractDate = () => {
        if (isToday) {
            const newDate = dayjs(currentDate).subtract(1, 'week');
            setCurrentDate(newDate);
        }

        if (isWeek) {
            const newDate = dayjs(currentDate).subtract(1, 'month');
            setCurrentDate(newDate);
        }

        if (isMonth) {
            const newDate = dayjs(currentDate).subtract(1, 'year');
            setCurrentDate(newDate);
        }
    };

    const settingDate = (type: string) => {
        setCurrentDate(dayjs());
        if (type === 'today') {
            setIsToday(true);
            setIsWeek(false);
            setIsMonth(false);
        }

        if (type === 'thisWeek') {
            setIsToday(false);
            setIsWeek(true);
            setIsMonth(false);
        }

        if (type === 'thisMonth') {
            setIsToday(false);
            setIsWeek(false);
            setIsMonth(true);
        }
    };

    useEffect(() => {
        if (currentDate) {
            setWeekNumber(getCurrentDayWeekNumber(currentDate));
        }
    }, [currentDate]);

    //  트레이너 담당회원 조회 API : (GET)
    const getTrainerMemberMealCount = useQuery(
        ['getTrainerMemberMealCountAPI', employeeId, currentDate],
        async () =>
            await getTrainerMemberMealCountAPI(employeeId, dayjs(currentDate).format('YYYY-MM-DD'), authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const dailyData = res.data.daily;
                    const weeklyData = res.data.weekly;
                    const monthlyData = res.data.monthly;

                    const dailyMaxCount = res.data.dailyMaxCount;
                    const weeklyMaxCount = res.data.weeklyMaxCount;
                    const monthlyMaxCount = res.data.monthlyMaxCount;

                    setDailyEntireData(dailyData);
                    setWeeklyEntireData(weeklyData);
                    setMonthlyEntireData(monthlyData);

                    setDailyMaxCount(dailyMaxCount);
                    setWeeklyMaxCount(weeklyMaxCount);
                    setMonthlyMaxCount(monthlyMaxCount);

                    let dailyDataArr: any = [
                        {
                            id: 'meal',
                            color: 'hsla(220, 70%, 55%, 1)',
                            data: [],
                        },
                        {
                            id: 'normal',
                            color: 'hsla(313, 96%, 78%, 1)',
                            data: [],
                        },
                    ];

                    let weeklyDataArr: any = [
                        {
                            id: 'meal',
                            color: 'hsla(220, 70%, 55%, 1)',
                            data: [],
                        },
                        {
                            id: 'normal',
                            color: 'hsla(313, 96%, 78%, 1)',
                            data: [],
                        },
                    ];

                    let monthlyDataArr: any = [
                        {
                            id: 'meal',
                            color: 'hsla(220, 70%, 55%, 1)',
                            data: [],
                        },
                        {
                            id: 'normal',
                            color: 'hsla(313, 96%, 78%, 1)',
                            data: [],
                        },
                    ];

                    // 당일
                    for (let i = 0; i < dailyData.length; i++) {
                        dailyDataArr[0].data.push({
                            x: dailyData[i].xLabel,
                            y: dailyData[i].dietMemberCount,
                        });
                        dailyDataArr[1].data.push({
                            x: dailyData[i].xLabel,
                            y: dailyData[i].nomalMemberCount,
                        });
                    }
                    // 이번 주
                    for (let i = 0; i < weeklyData.length; i++) {
                        weeklyDataArr[0].data.push({ x: weeklyData[i].xLabel, y: weeklyData[i].dietMemberCount });
                        weeklyDataArr[1].data.push({ x: weeklyData[i].xLabel, y: weeklyData[i].nomalMemberCount });
                    }

                    // 이번 달
                    for (let i = 0; i < monthlyData.length; i++) {
                        monthlyDataArr[0].data.push({ x: monthlyData[i].xLabel, y: monthlyData[i].dietMemberCount });
                        monthlyDataArr[1].data.push({ x: monthlyData[i].xLabel, y: monthlyData[i].nomalMemberCount });
                    }
                    setDailyFilterData(dailyDataArr);
                    setWeeklyFilterData(weeklyDataArr);
                    setMonthlyFilterData(monthlyDataArr);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!employeeId && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 데모버전 일간 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && dailyDummyData && dailyMaxCountDummyData) {
            const dailyData = dailyDummyData;
            const dailyMaxCount = dailyMaxCountDummyData;

            setDailyEntireData(dailyData);
            setDailyMaxCount(dailyMaxCount);

            let dailyDataArr: any = [
                {
                    id: 'meal',
                    color: 'hsla(220, 70%, 55%, 1)',
                    data: [],
                },
                {
                    id: 'normal',
                    color: 'hsla(219, 70%, 79%, 1)',
                    data: [],
                },
            ];

            // 당일
            for (let i = 0; i < dailyData.length; i++) {
                dailyDataArr[0].data.push({
                    x: dailyData[i].xLabel,
                    y: dailyData[i].dietMemberCount,
                });
                dailyDataArr[1].data.push({
                    x: dailyData[i].xLabel,
                    y: dailyData[i].nomalMemberCount,
                });
            }
            setDailyFilterData(dailyDataArr);
        }
    }, [dailyDummyData, dailyMaxCountDummyData]);

    // 데모버전 주간 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && weeklyDummyData && weeklyMaxCountDummyData) {
            const weeklyData = weeklyDummyData;
            const weeklyMaxCount = weeklyMaxCountDummyData;
            setWeeklyEntireData(weeklyData);
            setWeeklyMaxCount(weeklyMaxCount);

            let weeklyDataArr: any = [
                {
                    id: 'meal',
                    color: 'hsla(220, 70%, 55%, 1)',
                    data: [],
                },
                {
                    id: 'normal',
                    color: 'hsla(219, 70%, 79%, 1)',
                    data: [],
                },
            ];

            // 이번 주
            for (let i = 0; i < weeklyData.length; i++) {
                weeklyDataArr[0].data.push({ x: weeklyData[i].xLabel, y: weeklyData[i].dietMemberCount });
                weeklyDataArr[1].data.push({ x: weeklyData[i].xLabel, y: weeklyData[i].nomalMemberCount });
            }
            setWeeklyFilterData(weeklyDataArr);
        }
    }, [weeklyDummyData, weeklyMaxCountDummyData]);

    // 데모버전 월간 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && monthlyDummyData && monthlyMaxCountDummyData) {
            const monthlyData = monthlyDummyData;
            const monthlyMaxCount = monthlyMaxCountDummyData;

            setMonthlyEntireData(monthlyData);
            setMonthlyMaxCount(monthlyMaxCount);

            let monthlyDataArr: any = [
                {
                    id: 'meal',
                    color: 'hsla(220, 70%, 55%, 1)',
                    data: [],
                },
                {
                    id: 'normal',
                    color: 'hsl(219, 70%, 79%)',
                    data: [],
                },
            ];

            // 이번 달
            for (let i = 0; i < monthlyData.length; i++) {
                monthlyDataArr[0].data.push({ x: monthlyData[i].xLabel, y: monthlyData[i].dietMemberCount });
                monthlyDataArr[1].data.push({ x: monthlyData[i].xLabel, y: monthlyData[i].nomalMemberCount });
            }

            setMonthlyFilterData(monthlyDataArr);
        }
    }, [monthlyDummyData, monthlyMaxCountDummyData]);

    return (
        <Col
            style={{
                width: 1192,
                height: 612,
                marginTop: 24,

                borderRadius: 16,
                backgroundColor: colors.WHITE_50,
                border: '1px solid #C3D4F4',
            }}
        >
            <MemberMealListGraphHeader
                isToday={isToday}
                isWeek={isWeek}
                isMonth={isMonth}
                onClickSubtractDate={onClickSubtractDate}
                onClickAddDate={onClickAddDate}
                settingDate={settingDate}
                currentDate={currentDate}
                weekNumber={weekNumber}
                setCurrentDate={setCurrentDate}
            />
            <div
                style={{
                    width: 1192,
                    height: 614,
                    marginTop: 25,
                }}
            >
                {isToday && dailyFilterData && (
                    <MemberMealListDailyGraph
                        dailyFilterData={dailyFilterData}
                        dailyEntireData={dailyEntireData}
                        dailyMaxCount={dailyMaxCount}
                    />
                )}

                {isWeek && weeklyFilterData && (
                    <MemberMealListWeeklyGraph
                        weeklyFilterData={weeklyFilterData}
                        weeklyEntireData={weeklyEntireData}
                        weeklyMaxCount={weeklyMaxCount}
                    />
                )}

                {isMonth && monthlyFilterData && (
                    <MemberMealListMonthlyGraph
                        monthlyFilterData={monthlyFilterData}
                        monthlyEntireData={monthlyEntireData}
                        monthlyMaxCount={monthlyMaxCount}
                    />
                )}
            </div>
        </Col>
    );
};

export default MemberMealListGraph;
