// apiService.js

import { getOneMember } from '../api/member';

export const fetchOneMemberProfile = (
    params: {
        centerId: string;
        memberId: string;
    },
    authorization: string
) => ({
    queryKey: ['getCenterMemberList', params.centerId, params.memberId],
    queryFn: () => getOneMember(params.centerId, params.memberId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.member ?? [];
    },
});

type WeightRecord = {
    value: number;
    recordDate: string; // ISO 날짜 문자열로 정의
};

export type MemberProfile = {
    inviteCode: string;
    isInfo: boolean;
    isRequest: boolean;
    name: string; // 회원 이름
    email: string;
    profileImageUrl: string; // 프로필 이미지 url
    weight: WeightRecord[];
    created: string; // ISO 날짜 문자열로 정의
    createdAt: string; // 등록일
    updatedAt: string;
    __v: number;
    birthday: string; // 회원 생년월일 (ISO 날짜 문자열)
    exerciseVolume: string; // Enum으로 정의할 수 있지만, 현재는 string으로 정의
    gender: 'male' | 'female'; // 회원 성별
    height: number;
    isDeleted: boolean;
    phoneNumber: string; // 회원 휴대폰
    id: string; // memberId
    profileColorType: string; // 기본 프로필 색상
    profileName: string; // 기본 프로필 이름
    coachProfileImageUrl: string;
    coachProfileColorType: string;
    coachProfileName: string;
    coachId: string; // 담당강사 coachId
    coachName: string; // 담당강사 이름
    memo: string; // 메모
};
