import { useQueries, UseQueryOptions } from '@tanstack/react-query';
import { useState } from 'react';
import { getItemWithExpiration } from '../lib/utils';

type QueryConfig = {
    fetchFn: (
        params: any,
        authorization: string
    ) => {
        queryKey: any[];
        queryFn: () => Promise<any>;
        onSuccess?: (data: any) => any;
    };
    params: any;
    enabled?: boolean;
    suspense?: boolean;
};

const useMultipleQueries = (queries: QueryConfig[]) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const queryOptions: UseQueryOptions[] = queries.map((query) => {
        const { queryKey, queryFn, onSuccess } = query.fetchFn(query.params, authorization);

        return {
            queryKey,
            queryFn,
            enabled: !!authorization && !!query.enabled,
            onSuccess,
            suspense: query.suspense ?? true,
        };
    });

    const results = useQueries({ queries: queryOptions });

    const isLoading = results.some((result) => result.isLoading);

    const refinedResults = results.map((result, index) => {
        if (result.isSuccess && queries[index].fetchFn(queries[index].params, authorization).onSuccess) {
            const onSuccess = queries[index].fetchFn(queries[index].params, authorization).onSuccess;
            return {
                ...result,
                data: onSuccess ? onSuccess(result.data) : result.data,
            };
        }
        return result;
    });

    return { refinedResults, isLoading };
};

export default useMultipleQueries;
