import axios, { AxiosResponse } from "axios";

export /**
 * @method get
 * @brief 센터 멤버 조회
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1136/src/docs/Member.md#%EC%84%BC%ED%84%B0-%EB%A9%A4%EB%B2%84-%EC%A1%B0%ED%9A%8C
 */
const getOneMember = async (
  centerId: string,
  memberId: string,
  accessToken: string
): Promise<any> => {
  let response = {} as AxiosResponse<any, any>;
  try {
    response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_SERVER}/v1/members/center/${centerId}/member/${memberId}`,
      headers: { authorization: accessToken },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error: any) {
    // Error 😨
    switch (error.response.status) {
      case 401:
        return { status: error.response.status };
      case 403:
        return { status: error.response.status };
      case 404:
        return { status: error.response.status };
      case 406:
        return { status: error.response.status };
      case 500:
        alert("서버 내부 오류");
        return { status: error.response.status };
    }
  }
};
