import React from 'react';
import { colors } from '../lib/colors';
import { Row } from '../lib/utils';
import { I_EMPTY_PROFILE } from '../types/images';

type Props = {
    imageUrl: string | undefined;
    type: 'VeryBig' | 'Big' | 'Middle' | 'Small' | 'VerySmall';
    profileName?: string | '';
    profileColorType?: string | '';
    right?: number;
    left?: number;
};

const ProfileImage = ({ imageUrl, type, right, left, profileName, profileColorType }: Props) => {
    let VeryBigStyle = {
        width: 72,
        height: 72,
        marginRight: right ? right : 0,
        marginLeft: left ? left : 0,
        borderRadius: 72 / 2 + 1,
        overflow: 'hidden',
    };

    let VeryBigFontStyle = {
        fontSize: 24,
        fontWeight: '700',
        lineHeight: 36,
        color: colors.BLUE_900,
    };

    let BigStyle = {
        width: 56,
        height: 56,
        marginRight: right ? right : 0,
        marginLeft: left ? left : 0,
        borderRadius: 56 / 2 + 1,
        overflow: 'hidden',
    };

    let BigFontStyle = {
        fontSize: 18,
        fontWeight: '700',
        lineHeight: 28,
        color: colors.BLUE_900,
    };

    let MiddleStyle = {
        width: 48,
        height: 48,
        marginRight: right ? right : 0,
        marginLeft: left ? left : 0,
        borderRadius: 48 / 2 + 1,
        overflow: 'hidden',
    };
    let MiddleFontStyle = {
        fontSize: 16,
        fontWeight: '700',
        lineHeight: 24,
        color: colors.BLUE_900,
    };

    let SmallStyle = {
        width: 32,
        height: 32,
        marginRight: right ? right : 0,
        marginLeft: left ? left : 0,
        borderRadius: 32 / 2 + 1,
        overflow: 'hidden',
    };
    let SmallFontStyle = {
        fontSize: 12,
        fontWeight: '700',
        lineHeight: 18,
        color: colors.BLUE_900,
    };

    let VerySmallStyle = {
        width: 24,
        height: 24,
        marginRight: right ? right : 0,
        marginLeft: left ? left : 0,
        borderRadius: 24 / 2 + 1,
        overflow: 'hidden',
    };
    let VerySmallFontStyle = {
        fontSize: 12,
        fontWeight: '700',
        lineHeight: 18,
        color: colors.BLUE_900,
    };

    let ProfileStyle;
    let ProfileButtonStyle: any;
    if (type === 'VeryBig') {
        ProfileStyle = VeryBigStyle;
        ProfileButtonStyle = VeryBigFontStyle;
    }
    if (type === 'Big') {
        ProfileStyle = BigStyle;
        ProfileButtonStyle = BigFontStyle;
    }
    if (type === 'Middle') {
        ProfileStyle = MiddleStyle;
        ProfileButtonStyle = MiddleFontStyle;
    }
    if (type === 'Small') {
        ProfileStyle = SmallStyle;
        ProfileButtonStyle = SmallFontStyle;
    }

    if (type === 'VerySmall') {
        ProfileStyle = VerySmallStyle;
        ProfileButtonStyle = VerySmallFontStyle;
    }
    return (
        <Row style={ProfileStyle}>
            {imageUrl ? (
                <img
                    src={imageUrl}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            ) : profileName ? (
                <Row
                    style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: profileColorType,
                    }}
                >
                    <span style={ProfileButtonStyle}>{profileName}</span>
                </Row>
            ) : (
                <img
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    src={I_EMPTY_PROFILE}
                />
            )}
        </Row>
    );
};

export default ProfileImage;
