import { useMutation } from '@tanstack/react-query';
import {
    patchCenterUpdateMember,
    PatchCenterUpdateMemberParamsType,
    patchDisconnectMember,
    PatchDisconnectMemberParamsType,
    patchDisconnectMembers,
    PatchDisconnectMembersParamsType,
} from '../api/center';
import { MemberProfile } from './useMemberQueryService';

type Props = {
    fetchCoachList?: boolean;
    onPatchCenterUpdateMemberSuccess?: (data: MemberProfile) => void;
    onPatchCenterUpdateMemberError?: (error: any) => void;
    onPatchDisconnectMembersSuccess?: () => void;
    onPatchDisconnectMembersError?: (error: any) => void;
    onPatchDisconnectMemberSuccess?: () => void;
    onPatchDisconnectMemberError?: (error: any) => void;
};

const useCenterMutationService = ({
    onPatchCenterUpdateMemberSuccess,
    onPatchCenterUpdateMemberError,
    onPatchDisconnectMemberSuccess,
    onPatchDisconnectMemberError,
    onPatchDisconnectMembersSuccess,
    onPatchDisconnectMembersError,
}: Props) => {
    // const { data: memberListData, isLoading: isMemberListLoading } = useQuery(
    //   [
    //     "getCenterMemberListAPIQuery",
    //     authObject?.centerId,
    //     getCenterMemberListApiParams?.memberStatus,
    //     getCenterMemberListApiParams?.remainingPeriod,
    //     getCenterMemberListApiParams?.remainingSessions,
    //     getCenterMemberListApiParams?.coach,
    //     authorization,
    //   ],
    //   () =>
    //     getCenterMemberListAPI(
    //       authObject?.centerId,
    //       getCenterMemberListApiParams?.memberStatus ?? "all",
    //       getCenterMemberListApiParams?.remainingPeriod ?? "all",
    //       getCenterMemberListApiParams?.remainingSessions ?? "all",
    //       getCenterMemberListApiParams?.coach ?? "all",
    //       authorization
    //     ),
    //   {
    //     enabled: !!authorization && !!getCenterMemberListApiParams,
    //     suspense: true,
    //   }
    // );

    // const { data: coachListData, isLoading: isCoachListLoading } = useQuery(
    //   ["getCoachListForCenterQuery", authObject?.centerId, authorization],
    //   () => getCoachNameListAPI(authObject?.centerId, authorization),
    //   {
    //     enabled: !!authorization && fetchCoachList,
    //     suspense: true,
    //     onSuccess: (res: any) => {
    //       if (res.status === 200) {
    //         const data: GetCoachNameListResponse = res?.data;
    //         const _coachList = data.coachNameList;
    //         setDropDownCoachList(
    //           [{ code: "all", value: "전체" }].concat(
    //             _coachList.map((coach: GetCoachName) => ({
    //               code: coach.coachId,
    //               value: coach.name,
    //             }))
    //           )
    //         );
    //       }
    //     },
    //   }
    // );
    // const { data: memberScheduleHistoryData } = useQuery(
    //   ["getMemberScheduleHistoryQuery", authObject?.centerId, authorization],
    //   () =>
    //     getMemberScheduleHistory(
    //       getMemberScheduleHistoryParams?.centerId,
    //       getMemberScheduleHistoryParams?.coachId,
    //       getMemberScheduleHistoryParams?.memberId,
    //       getMemberScheduleHistoryParams?.date,
    //       authorization
    //     ),
    //   {
    //     enabled: !!authorization && !!getMemberScheduleHistoryParams,

    //     suspense: true,
    //   }
    // );

    const patchCenterUpdateMemberMutation = useMutation({
        mutationFn: async (params: PatchCenterUpdateMemberParamsType) => await patchCenterUpdateMember(params),
        onSuccess: (res) => {
            if (res.status === 200) {
                onPatchCenterUpdateMemberSuccess && onPatchCenterUpdateMemberSuccess(res.data.member);
            } else if (res.status === 400 || res.status === 401 || res.status === 403 || res.status === 404) {
                onPatchCenterUpdateMemberError && onPatchCenterUpdateMemberError(res.status);
            }
        },
    });
    const patchDisconnectMembersMutation = useMutation({
        mutationFn: async (params: PatchDisconnectMembersParamsType) => await patchDisconnectMembers(params),
        onSuccess: (res) => {
            if (res.status === 200) {
                onPatchDisconnectMembersSuccess && onPatchDisconnectMembersSuccess();
            } else if (res.status === 400 || res.status === 401 || res.status === 403 || res.status === 404) {
                onPatchDisconnectMembersError && onPatchDisconnectMembersError(res.status);
            }
        },
    });
    const patchDisconnectMemberMutation = useMutation({
        mutationFn: async (params: PatchDisconnectMemberParamsType) => await patchDisconnectMember(params),
        onSuccess: (res) => {
            if (res.status === 200) {
                onPatchDisconnectMemberSuccess && onPatchDisconnectMemberSuccess();
            } else if (res.status === 400 || res.status === 401 || res.status === 403 || res.status === 404) {
                onPatchDisconnectMemberError && onPatchDisconnectMemberError(res.status);
            }
        },
    });

    return {
        patchCenterUpdateMemberMutation,
        patchDisconnectMembersMutation,
        patchDisconnectMemberMutation,
    };
};

export default useCenterMutationService;
