import axios, { AxiosResponse } from 'axios';
import { createDataObject } from '../lib/utils';

export /**
 * @method get
 * @brief 관리자웹의 강사 리스트 드롭다운을 위한 강사이름, coachID를 불러오는 API
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1136/src/docs/Center.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9B%B9%EC%9D%98-%EA%B0%95%EC%82%AC-%EB%A6%AC%EC%8A%A4%ED%8A%B8-%EB%93%9C%EB%A1%AD%EB%8B%A4%EC%9A%B4%EC%9D%84-%EC%9C%84%ED%95%9C-%EA%B0%95%EC%82%AC%EC%9D%B4%EB%A6%84-coachid%EB%A5%BC-%EB%B6%88%EB%9F%AC%EC%98%A4%EB%8A%94-api
 */
const getCoachNameListAPI = async (centerId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/coach-name-list/${centerId}/`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 관리자의 센터 전체 활성회원 조회
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1136/src/docs/Center.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%EC%84%BC%ED%84%B0-%EC%A0%84%EC%B2%B4-%ED%99%9C%EC%84%B1%ED%9A%8C%EC%9B%90-%EC%A1%B0%ED%9A%8C
 */
const getCenterMemberListAPI = async (
    centerId: string,
    status: GetCenterMemberListStatus,
    remainingPeriod: GetCenterMemberListRemainingPeriod,
    remainingSessions: GetCenterMemberListRemainingSessions,
    assignedCoach: 'all' | string,
    accessToken: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/member-list/${centerId}?status=${status}&remainingPeriod=${remainingPeriod}&remainingSessions=${remainingSessions}&assignedCoach=${assignedCoach}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method patch
 * @brief 관리자의 회원 정보 수정
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1136/src/docs/Center.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%ED%9A%8C%EC%9B%90-%EC%A0%95%EB%B3%B4-%EC%88%98%EC%A0%95
 */
const patchCenterUpdateMember = async ({
    centerId,
    memberId,
    name,
    gender,
    birthday,
    phoneNumber,
    memo,
    newCoachId,
    accessToken,
}: PatchCenterUpdateMemberParamsType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    const data = await createDataObject({
        name,
        gender,
        birthday,
        phoneNumber,
        memo,
        newCoachId,
    });

    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/update-member/${centerId}/${memberId}`,
            headers: { authorization: accessToken },
            data: data,
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };

            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 관리자의 회원 스케줄 기록 조회
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1136/src/docs/Center.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%ED%9A%8C%EC%9B%90-%EC%8A%A4%EC%BC%80%EC%A4%84-%EA%B8%B0%EB%A1%9D-%EC%A1%B0%ED%9A%8C
 */
const getMemberScheduleHistory = async (
    centerId?: string,
    coachId?: string,
    memberId?: string,
    date?: string,
    accessToken?: string
): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/member-schedule-history/${centerId}/${coachId}/${memberId}?date=?${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method patch
 * @brief 관리자의 선택 회원 다중 연결해제 API
 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1136/src/docs/Center.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%EC%84%A0%ED%83%9D-%ED%9A%8C%EC%9B%90-%EB%8B%A4%EC%A4%91-%EC%97%B0%EA%B2%B0%ED%95%B4%EC%A0%9C-api
 */
const patchDisconnectMembers = async ({
    centerId,
    memberIds,
    accessToken,
}: PatchDisconnectMembersParamsType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/disconnect-members/${centerId}`,
            headers: { authorization: accessToken },
            data: {
                memberIds,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
export /**
 * @method patch
 * @brief 관리자의 회원 연결해제 API

 * @link https://github.com/mustMoveProject/rappo-project/blob/RAPPO-1136/src/docs/Center.md#%EA%B4%80%EB%A6%AC%EC%9E%90%EC%9D%98-%ED%9A%8C%EC%9B%90-%EC%97%B0%EA%B2%B0%ED%95%B4%EC%A0%9C-api
 */
const patchDisconnectMember = async ({
    centerId,
    coachId,
    memberId,
    accessToken,
}: PatchDisconnectMemberParamsType): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/disconnect-member/${centerId}`,
            headers: { authorization: accessToken },
            data: {
                coachId,
                memberId,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};

export type GetCenterMemberListRemainingPeriod =
    | 'all' // 전체 회원
    | 'lessThanOneWeek' //  7일 미만
    | 'lessThanTwoWeeks' //  14일 미만
    | 'lessThanOneMonth' //  30일 미만
    | 'lessThanHundredDays' //  100일 미만
    | 'lessThanOneYear' //  1년 미만
    | 'oneYearOrMore'; //  1년 이상

export type GetCenterMemberListRemainingSessions =
    | 'all' //  전체회원
    | 'lessThanThreeTimes' //  3회 미만
    | 'lessThanFiveTimes' //  5회 미만
    | 'lessThanTenTimes' //  10회 미만
    | 'lessThanTwentyTimes' //  20회 미만
    | 'twentyTimesOrMore'; //  20회 이상

export type GetCenterMemberListStatus =
    | 'all' //  전체 회원
    | 'active' //  수강 중
    | 'expired' //  만료
    | 'none'; //  대기 (회원권 없음)

export type GetCenterMemberListResponse = CenterMemberList;

interface Membership {
    _id: string;
    period: number;
    BGColor: string[];
    status: string;
    isDeleted: boolean;
    isCanceled: boolean;
    coachId: string;
    memberId: string;
    name: string;
    activationDate: string;
    expirationDate: string;
    startSessionValue: number;
    totalSession: number;
    memo: string;
    issuer: Issuer;
    pricePerSchedule: number;
    totalPrice: number;
    membershipTemplateId: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    id: string;
    currentSession: number;
    remainSession: number;
    remainPeriod: number;
}

export interface CenterMember {
    memberId: string;
    status: string;
    coachName: string;
    coachId: string;
    phoneNumber: string;
    age: number;
    gender: string;
    created: string;
    lastScheduleCreated: string;
    memberships: Membership[];
    isConnected: boolean;
}
interface CenterMemberList {
    centerMemberList: CenterMember[];
}

export type Issuer = {
    issuerId?: string;
    name?: string;
    issuerRole?: 'admin' | 'coach';
};

export type GetCoachName = {
    coachId: string;
    name: string;
};

export type GetCoachNameListResponse = {
    success: boolean;
    coachNameList: GetCoachName[];
};

export type PatchCenterUpdateMemberParamsType = {
    centerId?: string;
    memberId?: string;
    name?: string;
    gender?: string;
    birthday?: string;
    phoneNumber?: string;
    memo?: string;
    newCoachId?: string;
    accessToken?: string;
};
export type PatchDisconnectMembersParamsType = {
    centerId?: string;
    memberIds?: string[];
    accessToken?: string;
};
export type PatchDisconnectMemberParamsType = {
    centerId?: string;

    coachId?: string;
    memberId?: string;

    accessToken?: string;
};
