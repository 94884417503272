import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSingleQuery from '../../hook/useSingleQuery';
import { fetchMembershipForMember, MemberMembershipDataType } from '../../services/useMembershipQueryService';
import MemberMembershipManageComponents from './MemberMembershipManageComponents';
import MemberMembershipManageHistory from './MemberMembershipManageHistory';
import ModalMembershipUpdate from './ModalMembershipUpdate';

type Props = {
    createAlert: any;
};

const MemberMembershipManagePage = ({ createAlert }: Props) => {
    const location = useLocation();
    const membershipId = location.state?.membershipId;
    const memberName = location.state?.memberName;
    const coachName = location.state?.coachName;

    const { refinedResult: membershipData } = useSingleQuery({
        fetchFn: fetchMembershipForMember,
        params: {
            membershipId,
        },
        enabled: !!membershipId,
    });
    const membership = membershipData?.data as MemberMembershipDataType;

    //수강권 수정하기 모달
    const [isMembershipUpdateModalVisible, setIsMembershipUpdateModalVisible] = useState(false);

    return (
        <div className="flex flex-col pt-[112px] items-center bg-WHITE_200">
            <div className="flex flex-col w-[1192px] item-center justify-center ">
                <MemberMembershipManageComponents
                    membership={membership}
                    memberName={memberName}
                    coachName={coachName}
                    onClickUpdate={() => {
                        setIsMembershipUpdateModalVisible(true);
                    }}
                    createAlert={createAlert}
                />
                <MemberMembershipManageHistory schedules={membership.schedules} />
            </div>
            {isMembershipUpdateModalVisible && (
                <ModalMembershipUpdate
                    coachName={coachName}
                    memberName={memberName}
                    membership={membership}
                    setIsVisible={setIsMembershipUpdateModalVisible}
                    createAlert={createAlert}
                />
            )}
        </div>
    );
};

export default MemberMembershipManagePage;
