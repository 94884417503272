import React from 'react';
import { Row } from '../../lib/utils';
import { I_FLOATING } from '../../types/images';

const FloatingButton = ({ setIsRegisterPassModal }: any) => {
    return (
        <Row
            onClick={() => {
                setIsRegisterPassModal(true);
            }}
            style={{
                width: 64,
                height: 64,
                position: 'fixed',
                right: 64,
                bottom: 64,
                cursor: 'pointer',
            }}
        >
            <img src={I_FLOATING} style={{ width: 64, height: 64 }} />
        </Row>
    );
};

export default FloatingButton;
