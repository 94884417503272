import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import ModalFeedDetailHeader from './ModalFeedDetailHeader';
import ModalFeedDetailBody from './ModalFeedDetailBody';
import { Feed } from '../../../scenes/Feed/FeedPage';
import { Col } from '../../../lib/utils';
import ModalFeedDetailFooter from './ModalFeedDetailFooter';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    overflow: hidden;
`;

const ModalInner = styled.div`
    background: white;
    width: 1080px;
    height: 498px;
    border-radius: 8px;
    box-shadow: 0px 0px 36px 8px #18346a4d;
    display: flex;
    flex-direction: column;
    background: rgba(245, 249, 255, 1);
    overflow-y: auto; /* 세로 스크롤 가능 */
`;

type Props = {
    modalObject: Feed;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    setIsConditionModal?: Dispatch<SetStateAction<boolean>>;
    onClickImageAtDetailModal?: (arr: any) => void;
    onClickVideoAtDetailModal?: any;
};

const ModalFeedDetail = ({
    modalObject,
    setModalVisible,
    setIsConditionModal,
    onClickImageAtDetailModal,
    onClickVideoAtDetailModal,
}: Props) => {
    console.log('🚀 ~ modalObject:', modalObject);
    const contents = modalObject?.contents;
    const comments = modalObject?.comments;
    const reactions = modalObject?.reactions;
    const scheduleReport = modalObject?.scheduleReport;
    const receivers = modalObject?.receivers;
    const postType = modalObject?.postType;

    const initialPost = modalObject?.initialPost;

    useEffect(() => {
        // 모달이 열렸을 때 body에 스크롤 방지 클래스 추가
        document.body.style.overflow = 'hidden';
        // 컴포넌트가 unmount될 때 body에 스크롤 방지 클래스 제거
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    return (
        <ModalOverLay>
            <ModalInner>
                <ModalFeedDetailHeader modalObject={modalObject} setModalVisible={setModalVisible} />
                <Col style={{ width: 1080 }}>
                    <ModalFeedDetailBody
                        contents={contents}
                        scheduleReport={scheduleReport}
                        receivers={receivers}
                        postType={postType}
                        initialPost={initialPost}
                        setIsConditionModal={setIsConditionModal}
                        onClickImageAtDetailModal={onClickImageAtDetailModal}
                        onClickVideoAtDetailModal={onClickVideoAtDetailModal}
                    />
                    <ModalFeedDetailFooter comments={comments} reactions={reactions} />
                </Col>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalFeedDetail;
