import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, getItemWithExpiration, Row } from '../../lib/utils';
import { Body2Regular, Body3Light, Body3Regular, CaptionBold, Head4, Subtitle1Bold } from '../../lib/font';
import SvgIcon from '../SvgIcon';
import { colors } from '../../lib/colors';
import ProfileImage from '../ProfileImage';
import ColTitleAndTextInput from '../ColTitleAndTextInput';
import useCenterMutationService from '../../services/useCenterMutationService';
import { CodeValueType } from '../../types/types';
import TrashButton from '../Button/TrashButton';
import ColorRoundButton from '../Button/ColorRoundButton';
import WhiteRoundButton from '../Button/WhiteRoundButton';

import dayjs from 'dayjs';
import ModalAlert from '../modal/ModalAlert';
import WhiteSquareButton from '../Button/WhiteSquareButton';
import ColorSquareButton from '../Button/ColorSquareButton';
import { useNavigate } from 'react-router-dom';
import { MemberProfile } from '../../services/useMemberQueryService';
import Modal from '../modal/Modal';

const MemberProfileComponents = ({
    memberId,
    memberProfile,
    name,
    selectCoach,
    birthDay,
    selectGender,
    phoneNumber,
    memo,
    dropDownCoachList,
    isChange,

    setName,
    setSelectCoach,
    setBirthDay,
    setSelectGender,
    setPhoneNumber,
    setMemo,
    createAlert,
}: {
    memberId: string;
    memberProfile?: MemberProfile;
    name: string;
    selectCoach?: CodeValueType;
    birthDay: string;
    selectGender?: CodeValueType;
    phoneNumber: string | undefined;
    memo: string;
    dropDownCoachList?: CodeValueType[];
    isChange: boolean;
    setName: Dispatch<SetStateAction<string>>;
    setSelectCoach: Dispatch<SetStateAction<CodeValueType | undefined>>;
    setBirthDay: Dispatch<SetStateAction<string>>;
    setSelectGender: Dispatch<SetStateAction<CodeValueType | undefined>>;
    setPhoneNumber: Dispatch<SetStateAction<string | undefined>>;
    setMemo: Dispatch<SetStateAction<string>>;
    createAlert: any;
}) => {
    const navigate = useNavigate();
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));

    const { patchCenterUpdateMemberMutation, patchDisconnectMemberMutation } = useCenterMutationService({
        onPatchCenterUpdateMemberSuccess: () => {
            createAlert('회원 정보가 변경되었습니다.', '회원 프로필 정보가 변경되었습니다', false);
        },
        onPatchDisconnectMemberSuccess: () => {
            createAlert('회원 정보가 삭제되었습니다.', '회원 정보가 삭제되었습니다', true);
            //뒤로가기
            window.history.back();
        },
    });
    // 담당 강사 변경 모달
    const [isVisibleChangeCoach, setIsVisibleChangeCoach] = useState<boolean>(false);
    // 회원 삭제 모달
    const [isVisibleDeleteMember, setIsVisibleDeleteMember] = useState<boolean>(false);
    // 임시 강사
    const [tempCoach, setTempCoach] = useState<CodeValueType | undefined>(selectCoach);
    // 생년월일 변경
    const onChangeBirthDay = (e: ChangeEvent<HTMLInputElement>) => {
        const currentBirthDay = e.target.value;
        const regex = /^[0-9\b -]{0,10}$/;
        if (regex.test(currentBirthDay)) {
            setBirthDay(currentBirthDay);
        }
    };
    // 생년월일 '-'추가 정제로직
    useEffect(() => {
        if (birthDay) {
            if (birthDay.length === 8) {
                setBirthDay(birthDay.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
            }

            if (birthDay.length === 9) {
                setBirthDay(birthDay.replace(/-/g, ''));
            }
        }
    }, [birthDay]);

    const onClickUpdateButton = () => {
        const birthDayRegex = /^((19[0-9][0-9]|20[0-9][0-9])-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]))?$/;

        if (!birthDayRegex.test(birthDay)) {
            createAlert('입력 오류', '생년월일 형식이 잘못되었습니다 [ ex) 2000-01-01 ]', true);
            return;
        }
        patchCenterUpdateMemberMutation.mutate({
            centerId: authObject.centerId, //centerId:
            memberId: memberId, //memberId:
            name: name, //name:
            gender: selectGender?.code, //gender:
            birthday: birthDay, //birthday:
            phoneNumber: phoneNumber, //phoneNumber:
            memo: memo, //memo:
            newCoachId: selectCoach?.code, //newCoachId:
            accessToken: authorization, //accessToken:
        });
    };

    return (
        <div className="flex flex-col w-full h-full justify-center items-center mb-[80px]">
            <Row className="justify-between w-[1192px] items-center mb-[24px]">
                <Head4>회원 프로필</Head4>
                <div
                    className="flex flex-row h-[36px] border-[1px] border-BLUE_200 border-solid rounded-[48px] px-[16px] justify-center items-center bg-white"
                    onClick={() => window.history.back()}
                >
                    <SvgIcon
                        name="SvgLeftStrokeArrow"
                        stroke={colors.primary}
                        size={14}
                        fill="none"
                        className="mr-[16px]"
                    />
                    <Body3Regular style={{ color: colors.primary }}>뒤로가기</Body3Regular>
                </div>
            </Row>
            <div className="flex flex-col border-[1px] border-solid border-BLUE_200 rounded-[8px] w-[1192px] p-[56px] bg-white">
                <Row className="flex items-center mb-[36px]">
                    <ProfileImage
                        imageUrl={memberProfile?.profileImageUrl}
                        type="VeryBig"
                        profileName={memberProfile?.profileName}
                        profileColorType={memberProfile?.profileColorType}
                        right={16}
                    />
                    <Col className="flex justify-between h-[75%]">
                        <Row className="h-[24px]">
                            <div className="absolute mt-[4px] h-[16px] w-[16px] border-l-[1px] border-t-[1px] border-solid border-primary -rotate-45" />
                            <div className="flex ml-[8px] border-solid border-primary border-[1px] border-l-0 justify-center items-center px-[4px] rounded-[2px]">
                                <CaptionBold style={{ color: colors.primary }}>라포 연결 중</CaptionBold>
                            </div>
                        </Row>
                        <Body3Light>{`등록일: ${dayjs(memberProfile?.created).format('YYYY년 MM월 DD일')}`}</Body3Light>
                    </Col>
                </Row>
                <Row className="mb-[56px]">
                    <ColTitleAndTextInput
                        title="이름"
                        value={name}
                        onChange={(value) => setName(value)}
                        onClear={() => setName('')}
                        width={300}
                        mr={198}
                        textInputRightIcon={false}
                    />
                    <ColTitleAndTextInput
                        title="담당 강사"
                        value={selectCoach?.value || ''}
                        isDrowDown
                        width={300}
                        dropDownDatas={dropDownCoachList}
                        dropDownSelectData={selectCoach}
                        onClickDropDown={(item) => {
                            setTempCoach(item);
                            setIsVisibleChangeCoach(true);
                        }}
                        fontType="Body3Regular"
                    />
                </Row>
                <Row>
                    <Col className="mb-[60px] mr-[72px]">
                        <Row className="mb-[34px] items-center">
                            <Col style={{ width: 300, marginRight: 8 }}>
                                <Body3Regular style={{ color: colors.LAVEL_4, marginBottom: 8 }}>생년월일</Body3Regular>

                                <input
                                    name="birthDay"
                                    id="birthDay"
                                    value={birthDay}
                                    placeholder="생일을 입력해주세요! "
                                    onChange={onChangeBirthDay}
                                    style={{
                                        width: 300,
                                        height: 36,
                                        borderRadius: 36,
                                        border: '1px solid #D4DBE8',
                                        paddingLeft: 16,
                                        fontSize: 16,
                                        fontWeight: 400,
                                    }}
                                />
                            </Col>
                            <ColTitleAndTextInput
                                title="성별"
                                width={120}
                                isDrowDown
                                dropDownDatas={[
                                    { code: 'male', value: '남성' },
                                    { code: 'female', value: '여성' },
                                ]}
                                dropDownSelectData={selectGender}
                                onClickDropDown={(item) => setSelectGender(item)}
                                fontType={'Body3Regular'}
                            />
                        </Row>
                        <ColTitleAndTextInput
                            title="휴대폰 번호"
                            value={phoneNumber}
                            onChange={(value) => setPhoneNumber(value)}
                            width={384}
                            placeholder="- 없이 입력해주세요."
                            maxLength={13}
                            textInputRightIcon={false}
                        />
                    </Col>
                    <ColTitleAndTextInput
                        title="메모"
                        value={memo}
                        width={584}
                        height={138}
                        isMemo
                        onMemoChange={(e) => setMemo(e.target.value)}
                    />
                </Row>
                <Row className="w-full items-center justify-between">
                    <TrashButton text="회원 삭제" onClick={() => setIsVisibleDeleteMember(true)} height={32} />
                    <Row>
                        <WhiteRoundButton
                            text="수업 피드 바로가기"
                            size="small"
                            customStyle={{ width: 160, height: 36, marginRight: 32 }}
                            callBack={() => {
                                navigate('/feed/member', {
                                    state: {
                                        coachId: memberProfile?.coachId,
                                        memberId: memberId,
                                    },
                                });
                            }}
                        />
                        <ColorRoundButton
                            color={isChange ? colors.primary : colors.WHITE_700}
                            text="정보 변경하기"
                            size="small"
                            customStyle={{ width: 160, height: 36 }}
                            isDisabled={!isChange}
                            callBack={onClickUpdateButton}
                        />
                    </Row>
                </Row>
            </div>
            {isVisibleChangeCoach && (
                <ModalAlert
                    modalVisible={isVisibleChangeCoach}
                    setModalVisible={setIsVisibleChangeCoach}
                    customComponent={
                        <Col className="justify-center items-center w-[540px] h-[346px]">
                            <SvgIcon name={'SvgError'} fill="red" style={{ marginBottom: 8 }} />
                            <Subtitle1Bold style={{ color: colors.LAVEL_4, marginBottom: 12 }}>
                                담당 강사 변경
                            </Subtitle1Bold>
                            <Body2Regular style={{ marginBottom: 16 }}>
                                강사님이 변경됨에 따라 변경되는 요소들 입니다!
                            </Body2Regular>
                            <Col className="p-[16px] bg-WHITE_500 rounded-[8px] mb-[16px] w-full">
                                <Body3Regular>
                                    {'1. 이전 강사님과 회원님의 (변경 이후의) 모든 수업 일정들이'}
                                </Body3Regular>
                                <Body3Regular className="ml-[16px] mb-[8px]">{'삭제 처리 됩니다!'}</Body3Regular>
                                <Body3Regular>
                                    {'2. 과거 수업 일정, 운동 일지 및 컨디션 정보 등의 데이터는 그대로 '}
                                </Body3Regular>
                                <Body3Regular className="ml-[16px] mb-[4px]">{'확인하실 수 있습니다!'}</Body3Regular>
                            </Col>
                            <Row>
                                <WhiteSquareButton
                                    text={'취소'}
                                    size="middle"
                                    mr={8}
                                    callBack={() => {
                                        setIsVisibleChangeCoach(false);
                                    }}
                                />
                                <ColorSquareButton
                                    text={'변경'}
                                    size="middle"
                                    callBack={() => {
                                        setIsVisibleChangeCoach(false);
                                        setSelectCoach(tempCoach);
                                    }}
                                />
                            </Row>
                        </Col>
                    }
                />
            )}
            {isVisibleDeleteMember && (
                <Modal whiteViewHeight={346} whiteViewWidth={540} setIsVisible={setIsVisibleDeleteMember}>
                    <Col className="flex justify-center items-center w-full h-full px-[36px]">
                        <SvgIcon name={'SvgError'} fill="red" style={{ marginBottom: 8 }} />
                        <Subtitle1Bold style={{ color: colors.LAVEL_4, marginBottom: 12 }}>회원 삭제</Subtitle1Bold>
                        <Body2Regular style={{ marginBottom: 16 }}>해당 회원의 프로필 정보가 삭제됩니다!</Body2Regular>
                        <Col className="p-[16px]  bg-WHITE_500 rounded-[8px] mb-[16px] w-full">
                            <Body3Regular>{'1. 삭제후, 회원 검색 및 복구가 불가능합니다!'}</Body3Regular>
                            <Body3Regular>{'2. 삭제된 회원의 수강권은 자동으로 만료처리 됩니다!'}</Body3Regular>
                        </Col>
                        <Row>
                            <WhiteSquareButton
                                text={'취소'}
                                size="middle"
                                mr={16}
                                callBack={() => {
                                    setIsVisibleDeleteMember(false);
                                }}
                            />
                            <ColorSquareButton
                                text={'삭제'}
                                size="middle"
                                callBack={() => {
                                    setIsVisibleDeleteMember(false);
                                    patchDisconnectMemberMutation.mutate({
                                        centerId: authObject.centerId, //centerId:
                                        memberId: memberId, //memberId:
                                        coachId: memberProfile?.coachId, //coachId:
                                        accessToken: authorization, //accessToken:
                                    });
                                }}
                            />
                        </Row>
                    </Col>
                </Modal>
            )}
        </div>
    );
};

export default MemberProfileComponents;
