import React, { useState } from 'react';
import { Col, convertHourMin } from '../../lib/utils';
import { CaptionRegular, Subtitle1Bold } from '../../lib/font';
import Grid, { GridColProps } from '../Grid/Grid';
import { MemberScheduleDataType } from '../../services/useMembershipQueryService';
import dayjs from 'dayjs';

type Props = {
    schedules: MemberScheduleDataType[];
};

const MemberMembershipManageHistory = ({ schedules }: Props) => {
    console.log('🚀 ~ MemberMembershipManageHistory ~ schedules:', schedules);
    // 예정 , 시작시간이 현재시간보다 작으면 예정으로 표시

    // 날짜 , 수업시간, 회차, 상태
    const [colDefs, setColDefs] = useState<GridColProps[]>([
        {
            field: 'startTime',
            headerName: '날짜',
            align: 'center',
            type: 'date',
            dateFormat: 'YYYY-MM-DD',
        },
        {
            field: 'startTime',
            headerName: '수업 시간',
            align: 'center',
            customFormat: (value, row) => {
                return `${convertHourMin(value, 'text', true)} ~ ${convertHourMin(row.endTime, 'text', true)} (${dayjs(
                    row.endTime
                ).diff(dayjs(row.startTime), 'minute')}분)`;
            },
        },
        {
            field: 'currentSession',
            headerName: '회차',
            align: 'center',
            customFormat: (value, row) => {
                return `${value}/${row.totalSession} 회`;
            },
        },
        {
            field: 'attendanceStatus',
            headerName: '상태',
            align: 'center',
            booleanComponent: {
                fn: (row) => row.attendanceStatus === 'ATTENDANCE',
                trueComponent: (
                    <div className="flex w-full justify-center">
                        <Col className="flex flex-col justify-center bg-SUCCESS_50 w-[46px] h-[30px] rounded-[4px]">
                            <CaptionRegular className="text-SUCCESS">출석</CaptionRegular>
                        </Col>
                    </div>
                ),
                falseComponent: (
                    <div className="flex w-full justify-center">
                        <Col className="flex flex-col justify-center bg-ERROR_50 w-[46px] h-[30px] rounded-[4px]">
                            <CaptionRegular className="text-ERROR">노쇼</CaptionRegular>
                        </Col>
                    </div>
                ),
                etcFn: (row) => row.attendanceStatus === 'ATTENDANCE' && dayjs(row.startTime).isAfter(dayjs()),
                etcTrueComponent: (
                    <div className="flex w-full justify-center">
                        <Col className="flex flex-col justify-center bg-BLUE_50 w-[46px] h-[30px] rounded-[4px]">
                            <CaptionRegular className="text-BLUE_500">예정</CaptionRegular>
                        </Col>
                    </div>
                ),
            },
        },
    ]);
    return (
        <Col>
            <Subtitle1Bold>수강권 사용 기록</Subtitle1Bold>
            <Grid colDefs={colDefs} rowData={schedules} isInfinity={true} />
        </Col>
    );
};

export default MemberMembershipManageHistory;
