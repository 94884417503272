import { useEffect, useState } from 'react';
import { Col } from '../../lib/utils';
import ClassPassList from './ClassPassList/ClassPassList';
import ModalRegisterPass from './ModalRegisterPass/ModalRegisterPass';
import { useQuery } from '@tanstack/react-query';
import { getMembershipTemplatesListAPI } from '../../api/memberShip';
import _ from 'lodash';
import FloatingButton from './FloatingButton';
import ClassPassDetail from './ClassPassDetail/ClassPassDetail';
import ModalModifyPass from './ModalModifyPass/ModalModifyPass';

export interface SortType {
    value: string;
    label: string;
}

const ClassPassPage = ({ authObject, setAuthObject, authorization, createAlert }: any) => {
    const [pageType, setPageType] = useState<string>('list');

    const [isRegisterPassModal, setIsRegisterPassModal] = useState<boolean>(false); // 수강권 등록 모달여부
    const [isModifyPassModal, setIsModifyPassModal] = useState<boolean>(false); // 수강권 수정 모달여부

    const storedFirstSortType = localStorage.getItem('firstSortType');
    const storedSecondSortType = localStorage.getItem('secondSortType');
    const storedIsHideCoach = localStorage.getItem('isHideCoach');

    const [firstSortType, setFirstSortType] = useState(() => {
        return storedFirstSortType ? JSON.parse(storedFirstSortType) : { value: 'ENTIRE', label: '전체 보기' };
    });

    const [secondSortType, setSecondSortType] = useState(() => {
        return storedSecondSortType
            ? JSON.parse(storedSecondSortType)
            : {
                  value: 'RECENT',
                  label: '최근 생성 순',
              };
    });

    const [membershipTemplates, setMembershipTemplates] = useState<any>([]); // 수강권 리스트
    const [filterMembershipTemplates, setFilterMembershipTemplates] = useState<any>([]); // 수강권 필터 리스트

    const [detailObject, setDetailObject] = useState<any>(null); // 수강권 상세
    // 강사 숨기기 상태
    const [isHideCoach, setIsHideCoach] = useState(() => {
        return storedIsHideCoach ? JSON.parse(storedIsHideCoach) : false;
    });

    // (GET) : 수강권 상품 조회
    const getMembershipTemplatesList = useQuery(
        ['getMembershipTemplatesListAPI'],
        async () => await getMembershipTemplatesListAPI(authorization, authObject?.centerId),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res.data;

                    setMembershipTemplates(response?.membershipTemplates);
                    setFilterMembershipTemplates(response?.membershipTemplates);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 수강권 수정하기 클릭 이벤트
    const onClickModifyPass = () => {
        setIsModifyPassModal(true);
    };
    // 수강권상세
    const onClickDetailPage = (item: any) => {
        setDetailObject(item);
        setPageType('detail');
    };
    // 수강권상세
    const onClickListPage = () => {
        setDetailObject(undefined);
        setPageType('list');
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (pageType) {
            scrollToTop();
        }
    }, [pageType]);

    // 상단 필터2개
    useEffect(() => {
        if (filterMembershipTemplates) {
            let resultList = _.cloneDeep(membershipTemplates);

            // 첫번째 활성 / 비활성 필터
            if (firstSortType?.value === 'ACTIVE') {
                resultList = resultList.filter((item: any) => item?.isAvailable === true);
            } else if (firstSortType?.value === 'INACTIVE') {
                resultList = resultList.filter((item: any) => item?.isAvailable === false);
            }

            // 두번째 최근 생성, 최근 사용, 오래된 생성 정렬
            if (secondSortType?.value === 'RECENT') {
                resultList.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            } else if (secondSortType?.value === 'ACTIVE') {
                resultList.sort(
                    (a: any, b: any) => new Date(b.recentUsedTime).getTime() - new Date(a.recentUsedTime).getTime()
                );
            } else if (secondSortType?.value === 'OLD') {
                resultList.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
            } else if (secondSortType?.value === 'ALPHABET') {
                resultList.sort((a: any, b: any) => a.name.localeCompare(b.name));
            }
            if (isHideCoach) {
                // 강사 숨기기
                resultList = resultList.filter((item: any) => item?.issuer.issuerRole !== 'coach');
            }

            setFilterMembershipTemplates(resultList);
        }
    }, [firstSortType, secondSortType, isHideCoach, getMembershipTemplatesList]);

    return (
        <Col
            style={{
                marginTop: 64,
                width: '100%',
                alignItems: 'center',
                position: 'relative',
                marginBottom: 300,
            }}
        >
            {pageType === 'list' && (
                <ClassPassList
                    firstSortType={firstSortType}
                    setFirstSortType={setFirstSortType}
                    secondSortType={secondSortType}
                    setSecondSortType={setSecondSortType}
                    filterMembershipTemplates={filterMembershipTemplates}
                    onClickDetailPage={onClickDetailPage}
                    isHideCoach={isHideCoach}
                    setIsHideCoach={setIsHideCoach}
                    onClickHideCoach={() => {
                        setIsHideCoach(!isHideCoach);
                        localStorage.setItem('isHideCoach', JSON.stringify(!isHideCoach));
                    }}
                />
            )}

            {pageType === 'detail' && detailObject && (
                <ClassPassDetail
                    authObject={authObject}
                    detailObject={detailObject}
                    onClickModifyPass={onClickModifyPass}
                    createAlert={createAlert}
                    onClickListPage={onClickListPage}
                />
            )}

            {/* 플로팅 버튼 */}
            {pageType === 'list' && <FloatingButton setIsRegisterPassModal={setIsRegisterPassModal} />}

            {isRegisterPassModal && (
                <ModalRegisterPass
                    modalVisible={isRegisterPassModal}
                    setModalVisible={setIsRegisterPassModal}
                    createAlert={createAlert}
                />
            )}

            {/* 수강권 수정하기 */}
            {isModifyPassModal && detailObject && (
                <ModalModifyPass
                    setModalVisible={setIsModifyPassModal}
                    createAlert={createAlert}
                    detailObject={detailObject}
                    onUpdateSuccess={(data) => {
                        setDetailObject(data.membershipTemplate);
                    }}
                />
            )}
        </Col>
    );
};

export default ClassPassPage;
