import { useLocation, useNavigate } from 'react-router-dom';
import { Suspense, useEffect, useRef, useState } from 'react';
import { clearLocalStorage, Col, getItemWithExpiration, setItemWithExpiration } from './lib/utils';
import ModalAddTrainer from './components/ModalAddTrainer/ModalAddTrainer';
import ModalAlertOneButton from './components/modal/ModalAlertOneButton';
import ModalTerm from './components/modal/ModalTerm';
import DemoVersionQuitButton from './components/Button/DemoVersionQuitButton';
import useUsableStatus from './hook/useUsableStatus';
import ModalAlert from './components/modal/ModalAlert';
import Loading from './components/Loading/Loading';
import { useMutation } from '@tanstack/react-query';
import { createFreeTrialAPI } from './api/charge';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { sidebarStatusState } from './atoms/atom';
import Gnb from './components/Gnb/Gnb';
import useScreenWidth from './hook/useScreenWidth';
import RouteGroup from './scenes/RouteGroup';
import useAxiosInterceptor from './hook/useAxiosInterceptor';
import Modal from './components/modal/Modal';
import { Body3Regular, Subtitle1Bold } from './lib/font';
import ModalPopup from './components/modal/ModalPopup';

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const [sidebarStatus, setSidebarStatus] = useRecoilState(sidebarStatusState);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    // 공통 alert 창 상태값
    const [isAlert, setIsAlert] = useState<boolean>(false);
    const [alertTitle, setAlertTitle] = useState<string | null>(null);
    const [alertContent, setAlertContent] = useState<string | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [alertButtonContent, setAlertButtonContent] = useState<string | null>(null);
    const [alertCallBack, setAlertCallBack] = useState<any>(null);
    const [isTwoButton, setIsTwoButton] = useState<boolean>(false);
    const [twoButtonContent, setTwoButtonContent] = useState<string | null>(null);
    const [twoButtonCallBack, setTwoButtonCallBack] = useState<any>(null);

    // 공통 2버튼 alert 창 상태값
    const [isAlertTwoButton, setIsAlertTwoButton] = useState<boolean>(false);
    const [alertTwoButtonTitle, setAlertTwoButtonTitle] = useState<string>('취소');
    const [isErrorTwoButton, setIsErrorTwoButton] = useState<boolean>(false);
    const [alertTwoButtonContent, setAlertTwoButtonContent] = useState<string>('');
    const [alertTwoButtonCancelText, setAlertTwoButtonCancelText] = useState<string>('취소');
    const [alertTwoButtonConfirmText, setAlertTwoButtonConfirmText] = useState<string>('확인');
    const [alertTwoButtonConfirmCallBack, setAlertTwoButtonConfirmCallBack] = useState<any>(null);
    const [alertTwoButtonCancelCallBack, setAlertTwoButtonCancelCallBack] = useState<any>(null);
    const [isExitButton, setIsExitButton] = useState<boolean>(false);

    // 모달 스테이트
    const [isAddTrainer, setIsAddTrainer] = useState<boolean>(false);
    const [isTerm, setIsTerm] = useState<boolean>(false);
    const [isFreeTrial, setIsFreeTrial] = useState<boolean>(false);

    const [isVisibleGnb, setIsVisibleGnb] = useState<boolean>(false);
    const [isPopup, setIsPopup] = useState<boolean>(true);

    // 공통 모달 state 초기화
    const alertStateReset = () => {
        setIsAlert(false);
        setAlertTitle(null);
        setAlertContent(null);
        setIsError(false);
        setAlertButtonContent(null);
        setAlertCallBack(null);
    };

    /**
     * 공통 버튼 모달
     * @param title : 제목 텍스트
     * @param content : 본문 텍스트
     * @param isError : 아이콘 색여부
     * @param buttonContent : 버튼 텍스트 (선택)
     * @param callBack : 콜백함수 (선택)
     * @param isTwoButton : 두가지 버튼 여부 (선택)
     * @param twoButtonContent : 두번째 버튼 텍스트 (선택)
     * @param twoButtonCallBack : 두번째 버튼 콜백함수 (선택)
     */
    const createAlert = (
        title: string,
        content: string,
        isError: boolean,
        buttonContent?: string,
        callBack?: any,
        isTwoButton?: boolean,
        twoButtonContent?: string,
        twoButtonCallBack?: any
    ) => {
        setAlertTitle(title);
        setAlertContent(content);
        setIsError(isError);
        buttonContent && setAlertButtonContent(buttonContent);
        callBack && setAlertCallBack(() => callBack);

        isTwoButton && setIsTwoButton(isTwoButton);
        twoButtonContent && setTwoButtonContent(twoButtonContent);
        twoButtonCallBack && setTwoButtonCallBack(twoButtonCallBack);

        setIsAlert(true);
    };

    // 버튼 2개 모달 state 초기화
    const twoButtonAlertStateReset = () => {
        setIsAlertTwoButton(false);
        setAlertTwoButtonTitle('취소');
        setAlertTwoButtonContent('');
        setAlertTwoButtonCancelText('취소');
        setAlertTwoButtonConfirmText('확인');
        setAlertTwoButtonConfirmCallBack(null);
        setAlertTwoButtonCancelCallBack(null);
    };

    /**
     * @param title : 제목 텍스트
     * @param content : 본문 텍스트
     * @param isError : 경고인지 아닌지
     * @param cancelButtonText : 취소버튼 텍스트
     * @param confirmButtonText : 확인버튼 텍스트
     * @param alertTowButtonCancelCallBack : 취소버튼 콜백함수
     * @param alertTwoButtonConfirmCallBack : 확인버튼 콜백함수
     * @param isExitButton : 닫기 버튼 여부
     */
    const createTwoButtonAlert = (
        title: string,
        content: string,
        isError: boolean,
        cancelButtonText?: string,
        confirmButtonText?: string,
        alertTowButtonCancelCallBack?: any,
        alertTwoButtonConfirmCallBack?: any,
        isExitButton = false
    ) => {
        setAlertTwoButtonTitle(title);
        setAlertTwoButtonContent(content);
        setIsErrorTwoButton(isError);
        cancelButtonText && setAlertTwoButtonCancelText(cancelButtonText);
        confirmButtonText && setAlertTwoButtonConfirmText(confirmButtonText);
        alertTowButtonCancelCallBack && setAlertTwoButtonCancelCallBack(() => alertTowButtonCancelCallBack);
        alertTwoButtonConfirmCallBack && setAlertTwoButtonConfirmCallBack(() => alertTwoButtonConfirmCallBack);
        setIsAlertTwoButton(true);
        setIsExitButton(isExitButton);
    };

    // 트레이너 등록하기 버튼 클릭이벤트
    const onClickAddTrainer = () => {
        if (authObject) {
            // 무료체험 상태일 때
            if (authObject?.usableStatus == 'trial' || authObject?.usableStatus == 'trialPaymentPending') {
                setIsAddTrainer(true);
            }
            // 무료체험 상태가 아닐 때
            else {
                // 강사 인원 수가 플랜 제한 인원수 이상이라면 경고 alert, 추가 가능하다면 트레이너 등록 모달
                authObject?.planLimitCount <= authObject?.coachCount
                    ? createAlert(
                          '오류',
                          '현재플랜 에서의 강사 제한을 초과하였습니다\n 마이페이지 > 플랜정보 에서 플랜을 변경하여 주시길바랍니다',
                          true,
                          '확인',
                          () => navigate('/myPage')
                      )
                    : setIsAddTrainer(true);
            }
        }
    };

    // 이용약관 버튼 클릭이벤트
    const onClickTerm = () => {
        setIsTerm(true);
    };

    // 상단으로 가기 버튼
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (location) {
            // url 바뀔때마다 auth 최신화 밑 스크롤 최상단으로
            scrollToTop();
            setAuthorization(getItemWithExpiration('authorization'));
            setAuthObject(getItemWithExpiration('authObject'));
            setIsVisibleGnb(true);
            // 로그인 로그아웃 페이지 진입시 -> 로컬스토리지 초기화, 상태초기화
            if (location.pathname === '/signIn' || location.pathname === '/signUp') {
                clearLocalStorage();
                setIsVisibleGnb(false);
                setIsFreeTrial(false);
                setAuthorization(getItemWithExpiration('authorization'));
                setAuthObject(getItemWithExpiration('authObject'));
            }

            // 결제 만료 페이지로 갓을경우 사이드바안보이게
            // 결제 만료 상태가아닌데 직접접근 금지
            if (location.pathname.includes('expired')) {
                if (authObject?.usableStatus === 'needPlanToFree' || authObject?.usableStatus === 'needPlanToCharge') {
                    setIsVisibleGnb(false);
                } else {
                    navigate('/');
                }
            }

            // if (!(location.pathname.includes('daily') || location.pathname.includes('weekly'))) {
            //     setSidebarStatus('open');
            // }
        }
    }, [location]);

    // 윈도우의 뷰포트 에 따른 스크린 타입 감지
    useScreenWidth();
    //  구독 상태에 따른 경고창 페이지 분기
    useUsableStatus({
        oneButtonCallBack: createAlert,
        twoButtonCallBack: createTwoButtonAlert,
        authObject: authObject,
        setAuthObject: setAuthObject,
        alertStateReset: alertStateReset,
    });
    // axios 요청,응답 전역감지 : 토큰만료시(401,403) 로그인페이지로 리다이렉션
    useAxiosInterceptor({ createAlert, clearLocalStorage, navigate });

    // API (POST) : 무료체험 생성
    const createFreeTrial = useMutation({
        mutationFn: async () => await createFreeTrialAPI(authorization),
        onSuccess: (res) => {
            if (res.status === 201) {
                const response = res.data;
                const activationDate = response.activationDate;
                const expirationDate = dayjs(response.expirationDate).subtract(1, 'ms').toISOString();
                const expirationDelayDate = dayjs(response.expirationDate)
                    .subtract(1, 'ms')
                    .add(1, 'week')
                    .toISOString();

                const authObjectData = {
                    adminId: authObject?.adminId,
                    id: authObject?.id,
                    name: authObject?.name,
                    email: authObject?.email,
                    gender: authObject?.gender,
                    phoneNumber: authObject?.phoneNumber,
                    profileImageUrl: authObject?.profileImageUrl,
                    /** 센터 */
                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,

                    /** 구독플랜관련 */
                    coachCount: authObject?.coachCount,
                    memberCount: authObject?.memberCount,
                    activationDate: activationDate,
                    expirationDate: expirationDate,
                    expirationDelayDate: expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,
                    cardIssuingBank: authObject?.cardIssuingBank,
                    cardNumber: authObject?.cardNumber,
                    planAmount: authObject?.planAmount,
                    usableStatus: 'trial',
                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: authObject?.planLimitCount, // 플랜 제한 이용 인원
                    prevPlanLimitCount: authObject?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                    changedPlanLimitCount: authObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트

                    confirmUsableStatus: false,

                    testAccount: authObject?.testAccount,
                };

                setItemWithExpiration('authorization', authorization, 12);
                setItemWithExpiration('authObject', authObjectData, 12);
                setAuthObject(authObjectData);
            }
            if (res.status === 400) {
                createAlert('', '이미 7일 무료체험을 이용하고 있습니다', true);
            }
        },
    });

    // 토큰이없이 진입할때면 로그인페이지로 redirect
    useEffect(() => {
        if (!authorization) {
            navigate('/signIn', { replace: true });
        }

        if (authObject) {
            if (authObject.usableStatus === 'needAction') {
                setIsFreeTrial(true);
                createFreeTrial.mutate();
            } else {
                setIsFreeTrial(false);
            }
        }
    }, [authorization, authObject]);

    // 모바일 기기로 접속시 m.도메인으로 redirect
    useEffect(() => {
        const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            window.location.href = 'https://m.rappomanager.com';
        }
    }, []);

    return (
        <div id="App" style={{ position: 'relative' }}>
            {/* 상단 메뉴바 */}
            {authorization && isVisibleGnb && (
                <Gnb navigate={navigate} onClickAddTrainer={onClickAddTrainer} onClickTerm={onClickTerm} />
            )}

            <Suspense fallback={<div>Loading...</div>}>
                {/* 페이지 라우팅 */}
                <RouteGroup
                    createAlert={createAlert}
                    onClickTerm={onClickTerm}
                    createTwoButtonAlert={createTwoButtonAlert}
                    setIsLoading={setIsLoading}
                    onClickAddTrainer={onClickAddTrainer}
                    authObject={authObject}
                    setAuthObject={setAuthObject}
                    authorization={authorization}
                    scrollToTop={scrollToTop}
                />
            </Suspense>

            {/* 로딩 */}
            {isLoading && <Loading />}

            {/* 모달 1버튼 : 공통 알럿창 */}
            {isAlert && alertContent && (
                <ModalAlertOneButton
                    title={alertTitle}
                    content={alertContent}
                    isError={isError}
                    buttonContent={alertButtonContent}
                    callBack={alertCallBack}
                    isTwoButton={isTwoButton}
                    twoButtonContent={twoButtonContent}
                    twoButtonCallBack={twoButtonCallBack}
                    alertStateReset={alertStateReset}
                />
            )}

            {/* 모달 2버튼 : 공통알럿 */}
            {isAlertTwoButton && (
                <ModalAlert
                    modalVisible={isAlertTwoButton}
                    setModalVisible={setIsAlertTwoButton}
                    title={alertTwoButtonTitle}
                    content={alertTwoButtonContent}
                    callBack={alertTwoButtonConfirmCallBack}
                    cancelButtonText={alertTwoButtonCancelText}
                    confirmButtonText={alertTwoButtonConfirmText}
                    cancelCallBack={alertTwoButtonCancelCallBack}
                    twoButtonAlertStateReset={twoButtonAlertStateReset}
                    isError={isErrorTwoButton}
                    isExitButton={isExitButton}
                />
            )}

            {/* 모달 : 트레이너 등록하기*/}
            {isAddTrainer && (
                <ModalAddTrainer
                    modalVisible={isAddTrainer}
                    setModalVisible={setIsAddTrainer}
                    authorization={authorization}
                    authObject={authObject}
                    createAlert={createAlert}
                />
            )}

            {/* 모달 : 이용약관 */}
            {isTerm && <ModalTerm modalVisible={isTerm} setModalVisible={setIsTerm} />}

            {/* 모달 : 첫진입시 무료체험 7일 권유 */}
            {/* {isFreeTrial && authorization && (
                    <ModalFreeTrial
                        modalVisible={isFreeTrial}
                        setModalVisible={setIsFreeTrial}
                        createAlert={createAlert}
                        authorization={authorization}
                        authObject={authObject}
                        setAuthObject={setAuthObject}
                    />
                )} */}

            {/* 데모버전 상단 디자인 */}
            {authObject?.testAccount && <DemoVersionQuitButton navigate={navigate} />}

            {/* 페이지 탑 버튼 */}
            {/* {!(location.pathname === '/signIn' || location.pathname === '/signUp') && (
                    <HoverImage
                        src={I_SCROLL_TOP}
                        onClick={scrollToTop}
                        style={{ width: 80, height: 80, position: 'absolute', bottom: 44, right: 88 }}
                    />
                )} */}

            {/* {isPopup && <ModalPopup setIsPopup={setIsPopup} />} */}
        </div>
    );
};

export default App;
