import React from 'react';
import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { I_CONNECT, I_DISCONNECT, I_PENDING_CONNECT } from '../../types/images';
import { Body1Regular, Body2Regular, Body3Regular, CaptionLight } from '../../lib/font';
import ProfileImage from '../ProfileImage';
import NormalToggleButton from '../ToggleButton/NormalToggleButton';

type Props = {
    coachName: string;
    coachCode: string;
    isConnected: boolean;
    isDeleted: boolean;
    coachInfo: any;
    onClickPermissionChange: () => void;
};
const DetailTopLeft = ({ coachName, coachCode, isConnected, isDeleted, coachInfo, onClickPermissionChange }: Props) => {
    return (
        <Col
            style={{
                width: 381,
                height: 476,
                justifyContent: 'space-between',
            }}
        >
            <Col
                style={{
                    width: 381,
                    height: 400,
                    borderRadius: 16,
                    backgroundColor: colors.WHITE_50,
                    border: '1px solid #C3D4F4',
                    paddingTop: 36,
                    paddingLeft: 32,
                }}
            >
                <Row>
                    <ProfileImage
                        imageUrl={coachInfo?.profileImageUrl}
                        type="VeryBig"
                        profileName={coachInfo?.profileName}
                        profileColorType={coachInfo?.profileColorType}
                    />
                    <Col style={{ marginTop: 16, marginLeft: 16 }}>
                        {isConnected === true && isDeleted === false ? (
                            <img src={I_CONNECT} style={{ width: 88, height: 24 }} />
                        ) : isConnected === false && isDeleted === false ? (
                            <img src={I_PENDING_CONNECT} style={{ width: 114, height: 24 }} />
                        ) : (
                            <img src={I_DISCONNECT} style={{ width: 88, height: 24 }} />
                        )}

                        <CaptionLight style={{ color: colors.LAVEL_1, marginTop: 8 }}>
                            {'*변경 불가능한 정보입니다'}
                        </CaptionLight>
                    </Col>
                </Row>

                <Col style={{ width: 310, height: 80, justifyContent: 'space-between', marginTop: 34 }}>
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>성함</Body3Regular>
                    <Row
                        style={{
                            width: 310,
                            height: 48,
                            borderRadius: 48,
                            backgroundColor: colors.WHITE_500,

                            border: '1px solid #ACACAC',
                            marginTop: 8,
                            alignItems: 'center',
                            paddingLeft: 22,
                        }}
                    >
                        <Body1Regular style={{ color: colors.WHITE_900 }}>{coachName}</Body1Regular>
                    </Row>
                </Col>
                <Col style={{ width: 310, height: 80, justifyContent: 'space-between', marginTop: 48 }}>
                    <Body3Regular style={{ color: colors.LAVEL_4 }}>강사 코드</Body3Regular>
                    <Row
                        style={{
                            width: 310,
                            height: 48,
                            borderRadius: 48,
                            backgroundColor: colors.WHITE_500,

                            border: '1px solid #ACACAC',
                            marginTop: 8,
                            alignItems: 'center',
                            paddingLeft: 22,
                        }}
                    >
                        <Body1Regular style={{ color: colors.WHITE_900 }}>{coachCode}</Body1Regular>
                    </Row>
                </Col>
            </Col>

            <Row
                style={{
                    width: 381,
                    height: 64,
                    borderRadius: 16,
                    border: coachInfo?.isMembershipPermissionAll ? '1px solid #5fddbf' : '1px solid #acacac',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: coachInfo?.isMembershipPermissionAll ? colors.SUCCESS_50 : colors.GREY_50,
                    paddingLeft: 24,
                    paddingRight: 24,
                }}
            >
                <Body2Regular style={{ color: colors.LAVEL_3 }}>
                    수강권 관리 권한 {coachInfo?.isMembershipPermissionAll ? 'ON' : 'OFF'}
                </Body2Regular>
                <NormalToggleButton
                    isActive={coachInfo?.isMembershipPermissionAll}
                    callBack={onClickPermissionChange}
                />
            </Row>
        </Col>
    );
};

export default DetailTopLeft;
