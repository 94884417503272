import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import secureLocalStorage from 'react-secure-storage';

export const isSafari = () => {
    const userAgent = navigator.userAgent;
    return /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
};

/**
 * @param {number} 원본 화면의 width (px 단위)
 * @param {number} 원본 화면의 height (px 단위)
 * @return {function()} 현재 화면의 크기대로 frame을 조정하는 함수
 */
export const getResizeEventListener = (standardWidth: number, standardHeight: number) => {
    return () => {
        if (isSafari()) {
            // console.log('사파리');
            const root: any = document.querySelector('#root');
            const app: any = document.querySelector('#App');

            // 원하는 해상도로 width, height 고정
            app.style.width = `${standardWidth}px`;
            app.style.height = `${standardHeight}px`;

            let width = root.clientWidth;
            let height = width * (standardHeight / standardWidth);

            // style.transform을 이용하여, 화면을 크기를 조정
            app.style.transform = `scale(${width / standardWidth}, ${height / standardHeight})`;

            if (height > root.clientHeight) {
                height = root.clientHeight;
                width = height * (standardWidth / standardHeight);

                // style.transform을 이용하여, 화면을 크기를 조정
                app.style.transform = `scale(${width / standardWidth}, ${height / standardHeight})`;
            }
        } else {
            // console.log('사파리 아님');
            const root: any = document.querySelector('#root');
            const app: any = document.querySelector('#App');

            // 원하는 해상도로 width, height 고정
            app.style.width = `${standardWidth}px`;
            app.style.height = `${standardHeight}px`;

            let width = root.clientWidth;
            let height = width * (standardHeight / standardWidth);

            // style.zoom을 이용하여, 화면을 크기를 조정
            app.style.zoom = height / standardHeight;

            if (height > root.clientHeight) {
                height = root.clientHeight;
                width = height * (standardWidth / standardHeight);

                // style.zoom을 이용하여, 화면을 크기를 조정
                app.style.zoom = width / standardWidth;
            }
        }
    };
};

/**
 * @param {Dayjs} 현재 날짜
 * @return {function()} 현재 날짜가 속한 주의 주차를 구하는 함수
 */
export const getCurrentDayWeekNumber = (currentDate: Dayjs) => {
    const baseDate = dayjs(currentDate);
    const startWeekNumberDate = baseDate.startOf('month').startOf('week');
    const endWeekNumberDate = baseDate.startOf('week');
    let weekDiff = endWeekNumberDate.diff(startWeekNumberDate, 'day', true);
    let weekNumber = Math.floor(weekDiff) / 7 + 1; // 0주차는 존재할 수 없으니 1을 더해줌

    return weekNumber;
};

/**
 * @param {Dayjs} 현재 날짜
 * @return {function()} 해당 월이 몇주차로 이루어져있는지 ex(4주차, 5주차)
 */
export const getCurrentWeekNumberOfMonth = (currentDate: Dayjs) => {
    const baseDate = dayjs(currentDate);
    const startWeekNumberDate = baseDate.startOf('month').startOf('week');

    // 해당월이 몇주차로 이루어져 있는지
    const endMonthNumberDate = dayjs(currentDate).endOf('month').startOf('week');
    let weekNumbers = endMonthNumberDate.diff(startWeekNumberDate, 'day', true);
    let maxWeekNumberOfMonth = Math.floor(weekNumbers) / 7 + 1;

    return maxWeekNumberOfMonth;
};

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
`;

// 데이터와 만료 시간을 함께 저장하는 함수
export const setItemWithExpiration = (key: any, value: any, expirationInHours: any) => {
    const now = new Date();
    const expirationTime = now.getTime() + expirationInHours * 60 * 60 * 1000; // 현재 시간에 만료 시간을 더함
    const item = { value, expirationTime };
    secureLocalStorage.setItem(key, JSON.stringify(item));
};

// 만료 여부를 확인하고 데이터를 가져오는 함수
export const getItemWithExpiration = (key: any) => {
    const storedItem: any = secureLocalStorage.getItem(key);

    if (storedItem) {
        const parsedItem = JSON.parse(storedItem);
        const now = new Date();
        if (now.getTime() < parsedItem.expirationTime) {
            return parsedItem.value;
        } else {
            // 만료된 데이터는 삭제
            secureLocalStorage.removeItem(key);
            window.location.replace('/');
        }
    }
    return null; // 데이터가 없거나 만료됐을 경우 null 반환
};

// 로컬스토리지 비우기
export const clearLocalStorage = () => {
    secureLocalStorage.clear();
};

// 문자열을 받아 최대길이만큼 자르고 + '...' 붙이는함수
export const truncateString = (str: string, maxLength: number) => {
    if (str?.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    } else {
        return str;
    }
};

export const convertDate = (currentDate: Dayjs, format: string) => {
    const convertDateString = dayjs(currentDate).format(format);
    return convertDateString;
};

// #region FRONT_LEFT_NECT 글자 바꾸기 -> 앞면, 목, 왼쪽
export const translatePart = (part: Part): string => {
    const partsDictionary: { [key in Part]?: string } = {
        HEAD: '머리',
        NECT: '목',
        SHOULDER: '어깨',
        ELBOW: '팔꿈치',
        WRIST: '손목',
        HAND: '손',
        CHEST: '가슴',
        BACK: '등',
        SPINE: '척추',
        WAIST: '허리',
        ABDOMEN: '복부',
        PELVIS: '골반',
        HIPJOINT: '고관절',
        THIGHS: '허벅지',
        HAMSTRING: '뒷허벅지',
        KNEE: '무릎',
        SHANK: '정강이',
        CALF: '종아리',
        ANKLE: '발목',
        FOOT: '발',
        UPPERSPINE: '상부척추',
        LOWERSPINE: '하부척추',
        TAILBONE: '꼬리뼈',
    };

    return partsDictionary[part] ?? part;
};

export const translateDirection = (direction: Direction): string => {
    const directionDictionary: { [key in Direction]?: string } = {
        FRONT: '앞면',
        BACK: '뒷면',
    };

    return directionDictionary[direction] ?? direction;
};

export const translatePosition = (position: Position): string => {
    const positionDictionary: { [key in Position]?: string } = {
        LEFT: '왼쪽',
        CENTER: '가운데',
        RIGHT: '오른쪽',
    };
    return positionDictionary[position] ?? position;
};

export const splitAndTranslate = (inputString: string): string[] => {
    const parts = inputString.split('_');

    // Adjust based on your actual pattern, if needed
    if (parts.length === 3) {
        const [direction, position, part] = parts;
        return [
            translateDirection(direction as Direction),
            translatePosition(position as Position),
            translatePart(part as Part),
        ];
    }

    // Return the original string in an array if it doesn't match the pattern
    return [inputString];
};

export const isSameDate = (firstDate: Dayjs, secondDate: Dayjs) => {
    if (dayjs(firstDate).format('YYYY-MM-DD HH:mm') === dayjs(secondDate).format('YYYY-MM-DD HH:mm')) {
        return true;
    } else {
        return false;
    }
};

export const concatDateAndTimeDayjs = (_date: any, _time: any) => {
    const date = dayjs(_date).format('YYYY-MM-DD');
    const time = dayjs(_time).format('HH:mm:ss');

    const result = date + ' ' + time;

    return dayjs(result);
};
/**
 *
 * @brief data 파라미터로 받은 객체의 값이 undefined나 null이 아닌 경우에만 data 객체에 추가하는 함수
 * @example
 *  const data = await createDataObject({
 *   name,
 *   gender,
 *   birthday,
 *   phoneNumber,
 *   memo,
 *   newCoachId,
 * });
 */
export const createDataObject = async (params: { [s: string]: unknown } | ArrayLike<unknown>) => {
    let data: { [key: string]: any } = {};

    for (const [key, value] of Object.entries(params)) {
        if (value !== undefined && value !== null) {
            data[key] = value;
        }
    }

    return data;
};

type Direction = 'FRONT' | 'BACK';
type Position = 'LEFT' | 'CENTER' | 'RIGHT';
type Part =
    | 'HEAD'
    | 'NECT'
    | 'SHOULDER'
    | 'ELBOW'
    | 'WRIST'
    | 'HAND'
    | 'CHEST'
    | 'BACK'
    | 'SPINE'
    | 'WAIST'
    | 'ABDOMEN'
    | 'PELVIS'
    | 'HIPJOINT'
    | 'THIGHS'
    | 'HAMSTRING'
    | 'KNEE'
    | 'SHANK'
    | 'CALF'
    | 'ANKLE'
    | 'FOOT'
    | 'UPPERSPINE'
    | 'LOWERSPINE'
    | 'TAILBONE';

export const convertHourMin = (_str: string | undefined | Dayjs, format: 'dot' | 'text', isAmPm: boolean) => {
    const date = dayjs(_str);
    let hour = date.hour();
    const min = date.format('mm');

    let meridiem = '';
    if (isAmPm) {
        if (hour < 12) {
            meridiem = '오전';
        } else {
            meridiem = '오후';
            if (hour > 12) {
                hour -= 12;
            }
        }
        if (hour === 0) {
            hour = 12;
        }
    }

    const formattedTime = format === 'dot' ? `${hour.toString().padStart(2, '0')}:${min}` : `${hour}시 ${min}분`;

    return isAmPm ? `${meridiem} ${formattedTime}` : formattedTime;
};
