import React from 'react';

type Props = {
    width?: number;
    height?: number;
    placeholder?: string;
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    disabled?: boolean;
};

const MemoTextInput = ({ width, height, placeholder, value, onChange, disabled = false }: Props) => {
    return (
        <textarea
            style={{ width: width, height: height }}
            className=" p-4 border border-WHITE_600 rounded-[16px] focus:outline-none focus:ring-2 focus:ring-primary text-[16px] placeholder:text-GREY_200 resize-none"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            // 늘어나지못하게
        />
    );
};

export default MemoTextInput;
