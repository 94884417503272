import React, { useEffect, useRef, useState } from 'react';
import { Body3Light, Body3Regular, CaptionRegular } from '../../lib/font';
import { colors } from '../../lib/colors';
import dayjs from 'dayjs';
import { Col, Row } from '../../lib/utils';
import { I_LEFT_ANGLE, I_LEFT_TWO_ANGLE, I_RIGHT_ANGLE, I_RIGHT_TWO_ANGLE } from '../../types/images';
import RadiusTextInput from '../TextInput/RadiusTextInput';
import NewRoundedDropDown from '../DropDown/NewRoundedDropDown';
import SvgIcon from '../SvgIcon';
import ProfileImage from '../ProfileImage';
import TextSelector, { FontsType } from '../TextSelector';

const pageArray = [
    {
        code: '10',
        value: '10명',
    },
    {
        code: '15',
        value: '15명',
    },
    {
        code: '20',
        value: '20명',
    },
    {
        code: '25',
        value: '25명',
    },
    {
        code: '30',
        value: '30명',
    },
];

export type GridColProps = {
    headerName: string;
    field: string;
    type?: 'boolean' | 'date' | 'number' | 'string' | 'multiObject' | 'money';
    dateFormat?: string;
    customFormat?: (value: any, row: any) => string;
    customComponent?: (value: any, row: any) => JSX.Element;
    align?: 'left' | 'center' | 'right';
    // sticky?: boolean; // New prop to indicate if the column should be sticky
    width?: number;
    nullValue?: string;
    isSort?: boolean;
    // true 식
    booleanComponent?: {
        fn: (row: any) => boolean;
        trueComponent: JSX.Element;
        falseComponent: JSX.Element;
        // etc fn
        etcFn?: (row: any) => boolean;
        etcTrueComponent?: JSX.Element;
    };
    isProfile?: boolean;
    fontType?: FontsType;
};

type ComponentProps = 'pagination' | 'grid' | 'textInput' | 'dropdown';

type Props = {
    colDefs: GridColProps[];
    rowData: any[];
    onSortedData?: (data: any[]) => void;
    isCheckColumn?: boolean;
    leftTopCustomComponent?: (data: any, selectedData: any[]) => JSX.Element;
    centerTopCustomComponent?: () => JSX.Element;
    rightTopCustomComponent?: (data: any) => JSX.Element;
    leftBottomCustomComponent?: (data: any) => JSX.Element;
    centerBottomCustomComponent?: () => JSX.Element;
    rightBottomCustomComponent?: () => JSX.Element;
    leftTopComponent?: ComponentProps;
    centerTopComponent?: ComponentProps;
    rightTopComponent?: ComponentProps;
    textInputPlaceholder?: string;
    rightBottomComponent?: ComponentProps;
    centerBottomComponent?: ComponentProps;
    onClickRow?: (row: any) => void;
    mb?: number;
    isDisableHover?: boolean;
    isEnterChange?: boolean;
    isInfinity?: boolean;
};

/**
 *
 * @param param0
 * @example
 *  const [colDefs, setColDefs] = useState<GridColProps[]>([
 *      {
 *          field: 'name',
 *          headerName: '회원이름',
 *          align: 'center',
 *          // sticky: true,
 *          width: 132,
 *          isSort: true,
 *      },
 *      {
 *          field: 'status',
 *          headerName: '상태(담당강사)',
 *          customFormat: (value, row) => {
 *              return value === 'active' ? `활성 (${row.coachName})` : `비활성 (${row.coachName})`;
 *          },
 *          align: 'center',
 *          // sticky: true,
 *          width: 168,
 *      },
 *      {
 *          field: 'phoneNumber',
 *          headerName: '휴대폰 번호',
 *          width: 188,
 *          nullValue: '없음',
 *          align: 'center',
 *      },
 *      {
 *          field: 'gender',
 *          headerName: '나이(성별)',
 *          customFormat: (value, row) => `${row.age} (${value === 'male' ? '남성' : '여성'})`,
 *          align: 'center',
 *          width: 128,
 *      },
 *      {
 *          field: 'created',
 *          headerName: '등록일',
 *          type: 'date',
 *          dateFormat: 'YYYY / MM / DD',
 *          align: 'center',
 *          isSort: true,
 *          width: 168,
 *      },
 *      {
 *          field: 'lastScheduleCreated',
 *          headerName: '최근 수업일',
 *          type: 'date',
 *          dateFormat: 'YYYY / MM / DD',
 *          align: 'center',
 *          nullValue: '수업 없음',
 *          isSort: true,
 *          width: 168,
 *      },
 *      {
 *          field: 'memberships',
 *          headerName: '보유 수강권',
 *          customComponent: (value, row) => {
 *              return value.map((membership: any) => {
 *                  return (
 *                      <Col style={{ marginBottom: 8 }}>
 *                          <Row>
 *                              <Body3Regular>{membership.name}</Body3Regular>
 *                          </Row>
 *                          <Row style={{ justifyItems: 'center', alignItems: 'center' }}>
 *                              <Body3Regular>
 *                                  {`남은 회차 : ${membership.currentSession} / ${membership.totalSession}`}
 *                              </Body3Regular>
 *                              <Col className="w-1 h-1 bg-black rounded-full mx-[12px]" />
 *                              <Body3Regular>
 *                                  {`유효기간 : ${dayjs().diff(membership.expirationDate, 'day')}일`}
 *                              </Body3Regular>
 *                          </Row>
 *                      </Col>
 *                  );
 *              });
 *          },
 *      },
 *  ]);
 * @returns
 */
const Grid = ({
    colDefs,
    rowData,
    onSortedData,
    isCheckColumn = false,
    leftTopCustomComponent,
    centerTopCustomComponent,
    rightTopCustomComponent,
    leftTopComponent,
    centerTopComponent,
    rightTopComponent,
    leftBottomCustomComponent,
    centerBottomCustomComponent,
    rightBottomCustomComponent,
    rightBottomComponent,
    centerBottomComponent,
    textInputPlaceholder,
    onClickRow,
    mb = 0,
    isDisableHover = false,
    isEnterChange = false,
    isInfinity = false,
}: Props) => {
    const [columnWidths, setColumnWidths] = useState<number[]>([]); // 컬럼 너비 상태
    const columnRefs = useRef<(HTMLTableCellElement | null)[]>([]); // 컬럼 참조를 저장하는 ref
    const getLeftPosition = (index: number) => {
        return colDefs.slice(0, index).reduce((sum, col) => sum + (col.width || 0), 0);
    };
    useEffect(() => {
        const widths = columnRefs.current.map((ref) => ref?.offsetWidth || 0);
        setColumnWidths(widths);
    }, [columnRefs.current.length]);
    const [sortConfig, setSortConfig] = useState<{
        key: string | null;
        direction: string | null;
    }>({ key: null, direction: null });

    const requestSort = (key: string) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...rowData].sort((a, b) => {
            if (a[key as keyof typeof a] < b[key as keyof typeof b]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key as keyof typeof a] > b[key as keyof typeof b]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        onSortedData && onSortedData(sortedData);
    };
    // 검색어
    const [inputValue, setInputValue] = useState('');

    // 페이지 당 데이터 수
    const [pageSize, setPageSize] = useState(
        isInfinity
            ? {
                  code: '1000',
                  value: '1000명',
              }
            : {
                  code: '10',
                  value: '10명',
              }
    );

    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태

    // 검색어로 모든 필드에서 해당하는 값있으면 필터링
    const filteredData = rowData.filter((row) => {
        // 페이지 1로 초기화
        return colDefs.some((col) => {
            const value = row[col.field];

            // Check if the column has custom format
            const formattedValue = col.customFormat
                ? col.customFormat(value, row)
                : col.nullValue && !value
                ? col.nullValue
                : value;

            if (typeof formattedValue === 'string') {
                return formattedValue.toLowerCase().includes(inputValue.toLowerCase());
            }

            // If the value is an array (like memberships), iterate through its elements
            if (Array.isArray(value)) {
                return value.some((item) => {
                    // Check if the item is an object, then iterate through its keys
                    if (typeof item === 'object' && item !== null) {
                        return Object.values(item).some((nestedValue) => {
                            const formattedNestedValue = col.customFormat
                                ? col.customFormat(nestedValue, item)
                                : col.nullValue && !nestedValue
                                ? col.nullValue
                                : nestedValue;

                            return (
                                typeof formattedNestedValue === 'string' &&
                                formattedNestedValue.toLowerCase().includes(inputValue.toLowerCase())
                            );
                        });
                    }

                    // If the item is not an object, check the value directly
                    return typeof item === 'string' && item.toLowerCase().includes(inputValue.toLowerCase());
                });
            }

            // 객체인 경우 키값으로 검색
            if (typeof value === 'object' && value !== null) {
                return Object.values(value).some((nestedValue) => {
                    const formattedNestedValue = col.customFormat
                        ? col.customFormat(nestedValue, value)
                        : col.nullValue && !nestedValue
                        ? col.nullValue
                        : nestedValue;

                    return (
                        typeof formattedNestedValue === 'string' &&
                        formattedNestedValue.toLowerCase().includes(inputValue.toLowerCase())
                    );
                });
            }

            return false;
        });
    });

    const currentPageData = filteredData.slice(
        (currentPage - 1) * Number(pageSize.code),
        currentPage * Number(pageSize.code)
    ); // 현재 페이지에 해당하는 데이터 자르기
    const totalPages = Math.ceil(filteredData.length / Number(pageSize.code)); // 전체 페이지 수 계산

    const [checklist, setChecklist] = useState<any[]>([]);
    const [isAllChecked, setIsAllChecked] = useState(false);

    // 체크박스 전체 체크
    const handleAllCheck = () => {
        if (isAllChecked) {
            setChecklist([]);
        } else {
            setChecklist(rowData);
        }
        setIsAllChecked(!isAllChecked);
    };

    // 하나 체크박스 클릭
    const handleCheck = (item: any) => {
        if (checklist.includes(item)) {
            setChecklist(checklist.filter((i) => i !== item));
        } else {
            setChecklist([...checklist, item]);
        }
    };

    // 페이지네이션 렌더링
    const renderPagnation = () => {
        return (
            <Pagination
                onClickNumberPage={(page) => setCurrentPage(page)}
                onClickStartPage={() => setCurrentPage(1)}
                onClickPrevPage={() => setCurrentPage(currentPage - 1)}
                onClickNextPage={() => setCurrentPage(currentPage + 1)}
                onClickLastPage={() => setCurrentPage(totalPages)}
                currentPage={currentPage}
                totalPages={totalPages}
            />
        );
    };
    // 텍스트 입력 렌더링
    const renderTextInput = () => {
        return (
            <div style={{ width: 310, height: 36 }}>
                <RadiusTextInput
                    value={inputValue}
                    onChange={(value) => {
                        setCurrentPage(1);
                        setInputValue(value);
                    }}
                    clearable={true}
                    onClear={() => setInputValue('')}
                    placeholder={textInputPlaceholder}
                    width={310}
                    height={36}
                    isRightIcon
                    isEnterChange={isEnterChange}
                />
            </div>
        );
    };

    return (
        <div className={`w-full`} style={{ paddingBottom: mb }}>
            <Row className="flex justify-between items-center mb-4">
                {/* leftTop */}
                <Row className="flex-1">
                    {leftTopCustomComponent ? leftTopCustomComponent(filteredData, checklist) : null}
                </Row>
                {/* centerTop */}
                <Row className="flex-1 flex justify-center">
                    {centerTopCustomComponent ? centerTopCustomComponent() : null}
                    {centerTopComponent === 'pagination' && renderPagnation()}
                </Row>
                {/* rightTop */}
                <Row className="flex-1 flex justify-end">
                    {rightTopCustomComponent ? rightTopCustomComponent(filteredData) : null}
                    {rightTopComponent === 'textInput' && renderTextInput()}
                </Row>
            </Row>
            <div
                className={`overflow-hidden rounded-[16px] border border-solid border-BLUE_100`}
                style={{ overflowX: 'auto' }} // 추가된 부분: overflowX 스타일 설정
            >
                <table
                    className="table-auto min-w-full overflow-x-auto"
                    style={{
                        tableLayout: 'fixed',
                    }}
                >
                    <thead className="bg-white border-b-[1px] border-solid border-BLUE_100">
                        <tr>
                            {isCheckColumn && (
                                <th className="w-[72px] ">
                                    <CustomCheckbox checked={isAllChecked} onChange={handleAllCheck} />
                                </th>
                            )}
                            {colDefs.map((col, colIndex) => (
                                <th
                                    key={col.field}
                                    ref={(el) => (columnRefs.current[colIndex] = el)} // ref 설정
                                    onClick={() => col.isSort && requestSort(col.field)}
                                    className={` bg-white text-center uppercase tracking-wider cursor-pointer flex-row hover:bg-gray-100`}
                                    style={{
                                        width: col.width ? `${col.width}px` : 'auto',
                                        minWidth: col.width ? `${col.width}px` : 'auto',
                                        // left: col.sticky
                                        //   ? `${getLeftPosition(colIndex)}px`
                                        //   : "auto", // left 값 설정                    zIndex: col.sticky ? 10 : 0, // 추가된 부분: sticky 열의 z-index 지정
                                    }}
                                >
                                    <Row className="w-full h-full justify-center items-center">
                                        <Row className="py-[16px] px-[6px] items-center justify-center w-full h-full">
                                            <Body3Regular style={{ color: colors.LAVEL_3, marginRight: 8 }}>
                                                {col.headerName}
                                            </Body3Regular>
                                            {col.isSort ? (
                                                <Col>
                                                    <SvgIcon
                                                        name={'SvgFillUpArrow'}
                                                        style={{ marginBottom: 6 }}
                                                        width={14}
                                                        height={8}
                                                    />
                                                    <SvgIcon name={'SvgFillDownArrow'} width={14} height={8} />
                                                </Col>
                                            ) : null}
                                        </Row>
                                        <Col className="w-[1px] h-[24px] bg-BLUE_100" />
                                    </Row>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {filteredData && filteredData.length > 0 ? (
                        <tbody className="bg-white divide-y divide-gray-200">
                            {currentPageData.map((row, index) => (
                                <tr key={index} className={isDisableHover ? '' : 'hover:bg-[#ecf1fc]'}>
                                    {isCheckColumn && (
                                        <th className="px-[28px] ">
                                            <CustomCheckbox
                                                checked={checklist.includes(row)}
                                                onChange={() => handleCheck(row)}
                                            />
                                        </th>
                                    )}
                                    {colDefs.map((col, colIndex) => (
                                        <td
                                            key={col.field}
                                            onClick={() => onClickRow && onClickRow(row)}
                                            className={`px-6 py-4 whitespace-nowrap ${
                                                col.align === 'center'
                                                    ? 'text-center'
                                                    : col.align === 'right'
                                                    ? 'text-right'
                                                    : 'text-left'
                                            } `} // sticky인 경우 배경색 유지`}
                                            style={{
                                                width: col.width ? `${col.width}px` : 'auto',
                                                minWidth: col.width ? `${col.width}px` : 'auto',
                                                maxWidth: col.width ? `${col.width}px` : 'auto',

                                                // left: col.sticky
                                                //   ? `${getLeftPosition(colIndex)}px`
                                                //   : "auto", // left 값 설정,

                                                // zIndex: col.sticky ? 10 : 0, // 추가된 부분: sticky 열의 z-index 지정
                                            }}
                                        >
                                            <div>
                                                {col.booleanComponent ? (
                                                    col.booleanComponent.etcFn ? (
                                                        col.booleanComponent.etcFn(row) ? (
                                                            col.booleanComponent.etcTrueComponent
                                                        ) : col.booleanComponent.fn(row) ? (
                                                            col.booleanComponent.trueComponent
                                                        ) : (
                                                            col.booleanComponent.falseComponent
                                                        )
                                                    ) : col.booleanComponent.fn(row) ? (
                                                        col.booleanComponent.trueComponent
                                                    ) : (
                                                        col.booleanComponent.falseComponent
                                                    )
                                                ) : col.isProfile ? (
                                                    <Row
                                                        className="flex"
                                                        style={{
                                                            alignItems: 'center',
                                                            justifyContent:
                                                                col.align === 'center' ? 'center' : 'flex-start',
                                                        }}
                                                    >
                                                        <ProfileImage
                                                            imageUrl={row[col.field].profileImageUrl}
                                                            type="VerySmall"
                                                            profileName={row[col.field]?.profileName}
                                                            profileColorType={row[col.field]?.profileColorType}
                                                        />
                                                        <Body3Regular style={{ color: colors.LAVEL_3, marginLeft: 8 }}>
                                                            {row[col.field].alias}
                                                        </Body3Regular>
                                                    </Row>
                                                ) : (
                                                    <TextSelector
                                                        text={
                                                            col.type === 'date' && col.dateFormat
                                                                ? row[col.field]
                                                                    ? dayjs(row[col.field]).format(col.dateFormat)
                                                                    : col.nullValue
                                                                : col.type === 'money'
                                                                ? row[col.field]
                                                                      .toString()
                                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                                : col.customFormat
                                                                ? col.customFormat(row[col.field], row)
                                                                : col.customComponent
                                                                ? col.customComponent(row[col.field], row)
                                                                : col.type === 'number'
                                                                ? row[col.field]?.toLocaleString() || col.nullValue
                                                                : col.type === 'boolean'
                                                                ? row[col.field]
                                                                    ? 'O'
                                                                    : 'X'
                                                                : row[col.field]
                                                                ? row[col.field].toString()
                                                                : col.nullValue
                                                        }
                                                        className="text-LAVEL_3"
                                                        fontType={col.fontType ? col.fontType : 'Body3Regular'}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={8} className="text-center">
                                    <Col className=" justify-center items-center bg-white w-full py-[44px]">
                                        <SvgIcon
                                            name={'SvgError'}
                                            size={18}
                                            fill={colors.GREY_300}
                                            style={{ marginBottom: 12 }}
                                        />
                                        <Body3Light style={{ color: colors.LAVEL_2 }}>데이터가 없습니다</Body3Light>
                                    </Col>
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
            <Row className="flex justify-between items-center mt-[16px]">
                {/* leftBottom */}
                <Row className="flex-1">{leftBottomCustomComponent ? leftBottomCustomComponent(checklist) : null}</Row>
                {/* centerBottom */}
                <Row className="flex-1 flex justify-center">
                    {centerBottomCustomComponent ? centerBottomCustomComponent() : null}
                    {centerBottomComponent === 'pagination' && renderPagnation()}
                </Row>
                {/* rightBottom */}
                <Row className="flex-1 flex justify-end">
                    {rightBottomCustomComponent ? rightBottomCustomComponent() : null}
                    {rightBottomComponent === 'textInput' ? (
                        renderTextInput()
                    ) : rightBottomComponent === 'dropdown' ? (
                        <Row
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Body3Regular style={{ color: colors.LAVEL_1, marginRight: 8 }}>페이지 당 :</Body3Regular>
                            <NewRoundedDropDown
                                selectData={pageSize}
                                datas={pageArray}
                                onClickSort={(item) => setPageSize(item)}
                            />
                        </Row>
                    ) : null}
                </Row>
            </Row>
        </div>
    );
};

const Pagination = ({
    onClickNumberPage,
    onClickStartPage,
    onClickPrevPage,
    onClickNextPage,
    onClickLastPage,
    currentPage,
    totalPages,
}: {
    onClickNumberPage: (page: number) => void;
    onClickStartPage: () => void;
    onClickPrevPage: () => void;
    onClickNextPage: () => void;
    onClickLastPage: () => void;
    currentPage: number;
    totalPages: number;
}) => {
    const renderPages = () => {
        const pages = [];
        const maxVisiblePages = 5;
        const halfVisible = Math.floor(maxVisiblePages / 2);
        let startPage = Math.max(currentPage - halfVisible, 1);
        let endPage = Math.min(currentPage + halfVisible, totalPages);

        if (currentPage - halfVisible < 1) {
            endPage = Math.min(maxVisiblePages, totalPages);
        }
        if (currentPage + halfVisible > totalPages) {
            startPage = Math.max(totalPages - maxVisiblePages + 1, 1);
        }

        if (startPage > 1) {
            pages.push(
                <button
                    key={1}
                    style={{
                        width: 32,
                        height: 32,
                        margin: '0 4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: currentPage === 1 ? colors.BLUE_50 : 'transparent',
                        borderRadius: '4px',
                    }}
                    onClick={() => onClickNumberPage(1)}
                >
                    <CaptionRegular
                        style={{
                            color: currentPage === 1 ? colors.POINT_1 : colors.LAVEL_2,
                        }}
                    >
                        1
                    </CaptionRegular>
                </button>
            );

            if (startPage > 2) {
                pages.push(
                    <span key="start-ellipsis" style={{ margin: '0 4px' }}>
                        ...
                    </span>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    style={{
                        width: 32,
                        height: 32,
                        margin: '0 4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: currentPage === i ? colors.BLUE_50 : 'transparent',
                        borderRadius: '4px',
                    }}
                    onClick={() => onClickNumberPage(i)}
                >
                    <CaptionRegular
                        style={{
                            color: currentPage === i ? colors.POINT_1 : colors.LAVEL_2,
                        }}
                    >
                        {i}
                    </CaptionRegular>
                </button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(
                    <span key="end-ellipsis" style={{ margin: '0 4px', paddingTop: '12px' }}>
                        ...
                    </span>
                );
            }

            pages.push(
                <button
                    key={totalPages}
                    style={{
                        width: 32,
                        height: 32,
                        margin: '0 4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: currentPage === totalPages ? colors.BLUE_50 : 'transparent',
                        borderRadius: '4px',
                    }}
                    onClick={() => onClickNumberPage(totalPages)}
                >
                    <CaptionRegular
                        style={{
                            color: currentPage === totalPages ? colors.POINT_1 : colors.LAVEL_2,
                        }}
                    >
                        {totalPages}
                    </CaptionRegular>
                </button>
            );
        }

        return pages;
    };

    return (
        <Row className="flex justify-center mt-4">
            <button
                style={{
                    width: 32,
                    height: 32,
                    marginRight: 8,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={onClickStartPage}
                disabled={currentPage === 1}
            >
                <img style={{ width: 24, height: 24 }} src={I_LEFT_TWO_ANGLE} />
            </button>
            <button
                style={{
                    width: 32,
                    height: 32,
                    marginRight: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={onClickPrevPage}
                disabled={currentPage === 1}
            >
                <img style={{ width: 24, height: 24 }} src={I_LEFT_ANGLE} />
            </button>

            {renderPages()}

            <button
                style={{
                    width: 32,
                    height: 32,
                    marginLeft: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={onClickNextPage}
                disabled={currentPage === totalPages}
            >
                <img style={{ width: 24, height: 24 }} src={I_RIGHT_ANGLE} />
            </button>
            <button
                style={{
                    width: 32,
                    height: 32,
                    marginLeft: 8,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={onClickLastPage}
                disabled={currentPage === totalPages}
            >
                <img style={{ width: 24, height: 24 }} src={I_RIGHT_TWO_ANGLE} />
            </button>
        </Row>
    );
};

export const CustomCheckbox = ({
    checked,
    onChange,
    className,
}: {
    checked: boolean | undefined;
    onChange?: () => void;
    className?: string;
}) => {
    return (
        <label className={`relative inline-flex items-center cursor-pointer ${className}`}>
            <div
                onClick={onChange}
                className="w-[16px] h-[16px] border-[1px] border-primary border-solid rounded-md flex items-center justify-center "
            >
                {checked ? (
                    <svg
                        className=" text-primary"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            xmlns="http://www.w3.org/2000/svg"
                            d="M5 11L10.6667 16.6666L20 6.66663"
                            stroke="#3D73DD"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                ) : (
                    <div className="w-[15px] h-[15px]" />
                )}
            </div>
        </label>
    );
};

export default Grid;
