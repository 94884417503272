import React, { useState } from 'react';
import { Head4 } from '../../lib/font';
import RoundedDropDown from '../DropDown/RoundedDropDown';
import { SortType } from '../../scenes/CommunicationPage';
import { Row } from '../../lib/utils';

let sortOptions: any = [
    { value: 'registrationasc', label: '등록순' },
    { value: 'seniorityasc', label: '연차순' },
    { value: 'membercountdesc', label: '회원 많은 순' },
    { value: 'alphabetasc', label: '가나다 순' },
];

type Props = {
    coachSortType: any;
    setCoachSortType: any;
};

const TrainerManageHeader = ({ coachSortType, setCoachSortType }: Props) => {
    const [isOpenCoach, setIsOpenCoach] = useState<boolean>(false);
    const toggleCoachDropDown = () => {
        setIsOpenCoach(!isOpenCoach);
    };
    const handleCoachSelect = (option: any) => {
        setCoachSortType(option);
        setIsOpenCoach(false);
    };

    return (
        <Row style={{ width: 1192, paddingLeft: 8, justifyContent: 'space-between', alignItems: 'center' }}>
            <Head4 style={{ width: 216 }}>{`강사 리스트`}</Head4>

            <RoundedDropDown
                toggleSortDropDown={toggleCoachDropDown}
                sortType={coachSortType}
                isOpenSort={isOpenCoach}
                setIsOpenSort={setIsOpenCoach}
                sortOptions={sortOptions}
                handleSortSelect={handleCoachSelect}
                size="small"
            />
        </Row>
    );
};

export default TrainerManageHeader;
