import styled from 'styled-components';
import LogoButton from '../Button/LogoButton';
import TextButtonDropDown from '../DropDown/TextButtonDropDown';
import { Col, Row, getItemWithExpiration } from '../../lib/utils';
import { colors } from '../../lib/colors';
import ColorRoundButton from '../Button/ColorRoundButton';
import ProfileImage from '../ProfileImage';
import { Body3Bold, CaptionRegular, OverlineLight } from '../../lib/font';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { I_CIRCLE_QUESTION, I_POPUP_EXIT } from '../../types/images';
import GnbMyInfo from './GnbMyInfo';

const GnbContainer = styled.div`
    width: 100vw;
    height: 64px;
    top: 0;
    background-color: rgba(245, 249, 255);
    backdrop-filter: blur(10px);
    z-index: 999;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
`;

const TextButtonsContainer = styled.div`
    position: relative;
    /* width: 632px; */
    height: 64px;
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const RightButtonsContainer = styled.div`
    position: absolute;
    width: 346+165;
    height: 64px;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MyInfoButton = styled.button<{ isActive: boolean }>`
    width: 245px;
    height: 64px;
    padding-left: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background-color: ${({ isActive }: any) => (isActive ? '#ECF1FC' : 'rgba(245, 249, 255, 0.7)')};

    &:hover {
        background-color: #ecf1fc;
    }
`;

const Gnb = ({ navigate, onClickAddTrainer, onClickTerm }: any) => {
    const location = useLocation();
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    const [isVisibleInfo, setIsVisibleInfo] = useState<boolean>(false);

    const onClickToggleModal = () => {
        setIsVisibleInfo(!isVisibleInfo);
    };

    let memberManage = [
        {
            nav: 'memberList',
            name: '회원 리스트',
            callBack: () => {
                navigate('/memberList');
            },
        },
        {
            nav: 'memberCommunication',
            name: '회원 소통 상자',
            callBack: () => {
                navigate('/member/communication');
            },
        },
    ];

    let scheduleManage = [
        {
            nav: 'calendar',
            name: '스케줄 캘린더',
            callBack: () => {
                navigate('/schedule');
            },
        },
        {
            nav: 'scheduleHistory',
            name: '스케줄 변경 / 취소',
            callBack: () => {
                navigate('/schedule/history');
            },
        },
    ];

    return (
        // <GnbContainer style={{ position: location.pathname.includes('schedule') ? 'absolute' : 'fixed' }}>
        <GnbContainer style={{ position: 'fixed' }}>
            <LogoButton
                callBack={() => {
                    navigate('/dash');
                }}
            />

            <TextButtonsContainer>
                <TextButtonDropDown
                    isActive={location.pathname.includes('member')}
                    list={memberManage}
                    text={'회원 관리'}
                    isDropDown={true}
                />
                <TextButtonDropDown
                    isActive={location.pathname.includes('trainerManage')}
                    text={'강사 관리'}
                    callBack={() => {
                        navigate('/trainerManage');
                    }}
                    isDropDown={false}
                />
                <TextButtonDropDown
                    isActive={location.pathname.includes('schedule')}
                    text={'스케줄 관리'}
                    list={scheduleManage}
                    isDropDown={true}
                />
                <TextButtonDropDown
                    text={'수업 피드'}
                    isDropDown={false}
                    callBack={() => {
                        navigate('/feed');
                    }}
                    isActive={location.pathname.includes('feed')}
                />
                <TextButtonDropDown
                    text={'수강권 관리'}
                    isDropDown={false}
                    isActive={location.pathname.includes('classPass')}
                    callBack={() => {
                        navigate('/classPass');
                    }}
                />
            </TextButtonsContainer>

            <RightButtonsContainer>
                <Row style={{ marginRight: 20 }}>
                    <Row
                        style={{ cursor: 'pointer', height: 24, alignItems: 'center' }}
                        onClick={() => {
                            window.open(
                                'https://mustmove.notion.site/568797c259034056a29b7fe29d7de7fa?pvs=4',
                                '_blank'
                            ); // 새 탭에서 링크 열기
                        }}
                    >
                        <img
                            src={I_CIRCLE_QUESTION}
                            style={{ width: 16, height: 16, marginLeft: 16, marginRight: 3 }}
                        />
                        <CaptionRegular style={{ color: colors.POINT_1, cursor: 'pointer' }}>
                            라포 이용방법
                        </CaptionRegular>
                    </Row>
                </Row>
                <ColorRoundButton callBack={onClickAddTrainer} isGradient={true} size={'tiny'} text={'등록하기'} />
                <Col style={{ width: 1, height: 24, backgroundColor: colors.WHITE_600, marginLeft: 24 }} />

                <MyInfoButton type="button" onClick={onClickToggleModal} isActive={isVisibleInfo}>
                    <ProfileImage
                        type="Small"
                        imageUrl={authObject?.profileImageUrl}
                        profileName={authObject?.profileName}
                        profileColorType={authObject?.profileColorType}
                        right={8}
                    />

                    <Col style={{ marginLeft: 16, alignItems: 'flex-start' }}>
                        <CaptionRegular style={{ color: colors.LAVEL_3 }}>{`${authObject?.name} 님`}</CaptionRegular>
                        <OverlineLight
                            style={{ color: colors.LAVEL_3 }}
                        >{`${authObject?.centerName} 센터`}</OverlineLight>
                    </Col>
                </MyInfoButton>

                {/* 프로필 누를시 나오는 인포모달 */}
                {isVisibleInfo && (
                    <GnbMyInfo
                        authObject={authObject}
                        setIsVisibleInfo={setIsVisibleInfo}
                        navigate={navigate}
                        onClickTerm={onClickTerm}
                    />
                )}
            </RightButtonsContainer>
        </GnbContainer>
    );
};

export default Gnb;
