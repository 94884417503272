import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import {
    connectingTrainerAPI,
    deleteTrainerAPI,
    getTrainerInfoAtManageAPI,
    modifyTrainerProfileAPI,
    updateTrainerPermissionAPI,
} from '../../api/trainer';
import DetailHeader from '../../components/TrainerInformation/DetailHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import DetailTopLeft from '../../components/TrainerInformation/DetailTopLeft';
import DetailTopRight from '../../components/TrainerInformation/DetailTopRight';
import ModalMemberInfo from '../../components/ModalMemberInfo/ModalMemberInfo';
import ModalClassHistory from '../../components/ModalClassHistory/ModalClassHistory';
import ModalAlert from '../../components/modal/ModalAlert';
import ModalReRegister from '../../components/modal/ModalReRegister';
import { storeUserFlow } from '../../api/adminuserflow';
import { trainerProfileDummyData } from '../../lib/dummyData';
import LargeToggleButton from '../../components/Button/LargeToggleButton';
import ManageMember from '../../components/TrainerInformation/ManageMember/ManageMember';
import ClassHistory from '../../components/TrainerInformation/ClassHistory/ClassHistory';
import ModalCoachDelete from './ModalCoachDelete';

const TrainerInformationPage = ({ createAlert, onClickAddTrainer }: any) => {
    /**
     * isConnected : false , isDeleted :false -> 수락 대기중 상태
     * isConnected : true , isDeleted :false -> 정상 활성화 상태
     * isConnected : true , isDeleted :true -> 비활성화 상태 (센터에서 삭제된 트레이너)
     * isConnected : false , isDeleted :true -> 강사가 탈퇴한 상태 (센터에서 삭제 + 트레이너라포 탈퇴)
     */
    const navigate = useNavigate();
    const location = useLocation();

    const queryClient = useQueryClient();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));

    // 라우트로 전달받은 파라미터
    const employeeId = location.state.employeeId;
    const isConnected = location.state.isConnected;
    const isDeleted = location.state.isDeleted;

    const [pageType, setPageType] = useState<'manageMember' | 'classHistory'>('manageMember');

    // 초기값 세팅
    const [coachId, setCoachId] = useState<string>('');
    const [coachName, setCoachName] = useState<string>('');
    const [coachCode, setCoachCode] = useState<string>('');
    const [totalMemberCount, setTotalMemberCount] = useState<number>(0);
    const [activeMemberCount, setActiveMemberCount] = useState<number>(0);
    const [isShareOnetimeSchedule, setIsShareOnetimeSchedule] = useState<boolean>(true);
    const [coachInfo, setCoachInfo] = useState<any>(null);

    // 담당회원정보 에서 수업기록을 누를때 멤버이름 저장 변수
    const [searchMemberName, setSearchMemberName] = useState<string>('');
    // 성별 샐랙트박스 상태
    const [isOpenGender, setIsOpenGender] = useState<boolean>(false);

    // 수정가능 값
    const [dateBirth, setDateBirth] = useState<string>('');
    const [selectedGender, setSelectedGender] = useState({ value: '', label: '선택' });
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [dateJoinCompany, setDateJoinCompany] = useState<string>('');
    const [position, setPosition] = useState<string>('');

    // 모달 플래그
    const [isMemberInfoModal, setIsMemberInfoModal] = useState<boolean>(false);
    const [isClassHistoryModal, setIsClassHistoryModal] = useState<boolean>(false);
    const [isDeleteAlertModal, setIsDeleteAlertModal] = useState<boolean>(false);
    const [isReRegisterModal, setIsReRegisterModal] = useState<boolean>(false);

    // 담당회원 정보 > 수업히스토리 클릭시 뒤로가기 버튼을위한 플래그
    const [isClickedHistoryAtInfoModal, setIsClickedHistoryAtInfoModal] = useState<boolean>(false);

    // 변경감지 플래그
    const [apiData, setApiData] = useState<any>({
        dateBirth: '',
        gender: '',
        email: '',
        phoneNumber: '',
        dateJoinCompany: '',
        position: '',
    });
    const [isChangedDateBirth, setIsChangedDateBirth] = useState<boolean>(false);
    const [isChangedGender, setIsChangedGender] = useState<boolean>(false);
    const [isChangedEmail, setIsChangedEmail] = useState<boolean>(false);
    const [isChangedPhoneNumber, setIsChangedPhoneNumber] = useState<boolean>(false);
    const [isChangedJoinDate, setIsChangedJoinDate] = useState<boolean>(false);
    const [isChangedPosition, setIsChangedPosition] = useState<boolean>(false);

    let changeFlag =
        isChangedDateBirth ||
        isChangedGender ||
        isChangedEmail ||
        isChangedPhoneNumber ||
        isChangedJoinDate ||
        isChangedPosition;

    const onClickOutSide = () => {
        if (isOpenGender) {
            setIsOpenGender(false);
        }
    };

    const onClickBackButton = () => {
        navigate(-1);
    };
    // 생년월일 변경
    const onChangeDateBirth = (e: ChangeEvent<HTMLInputElement>) => {
        const currentDateBirth = e.target.value;
        const regex = /^[0-9\b -]{0,10}$/;
        if (regex.test(currentDateBirth)) {
            setDateBirth(currentDateBirth);
        }
    };

    // 이메일 변경
    const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const currentEmail = e.target.value;
        setEmail(currentEmail.trim());
    };

    // 휴대폰 번호 변경
    const onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const currentPhoneNumber = e.target.value;
        const regex = /^[0-9\b -]{0,13}$/;
        if (regex.test(currentPhoneNumber)) {
            setPhoneNumber(currentPhoneNumber);
        }
    };

    // 입사일 변경
    const onChangeDateJoinCompany = (e: ChangeEvent<HTMLInputElement>) => {
        const currentDateJoinCompany = e.target.value;
        const regex = /^[0-9\b -]{0,10}$/;
        if (regex.test(currentDateJoinCompany)) {
            setDateJoinCompany(currentDateJoinCompany);
        }
    };

    // 이메일 변경
    const onChangePosition = (e: ChangeEvent<HTMLInputElement>) => {
        const currentPosition = e.target.value;
        setPosition(currentPosition.trim());
    };

    // 담당 회원정보 버튼 클릭이벤트
    const onClickMemberInfo = () => {
        setIsMemberInfoModal(true);
    };

    // 수업 히스토리 버튼 클릭이벤트
    const onClickClassHistory = () => {
        setIsClassHistoryModal(true);
    };

    // 담당회원정보 > 수업기록 > 뒤로가기 버튼 누를시
    const onClickBackMemberInfo = () => {
        setIsClassHistoryModal(false);
        setIsClickedHistoryAtInfoModal(false);
        setIsMemberInfoModal(true);
    };

    // 저장하기 버튼 이벤트
    const onClickSave = () => {
        const dateRegex = /^((19[0-9][0-9]|20[0-9][0-9])-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]))?$/;

        if (!dateRegex.test(dateBirth)) {
            createAlert('', '생년월일 형식이 잘못되었습니다');
            return;
        }

        if (!dateRegex.test(dateJoinCompany)) {
            createAlert('', '입사일 형식이 잘못되었습니다');
            return;
        }

        modifyTrainerProfile.mutate();
    };

    // 담당회원정보 > 수업기록 버튼클릭이벤트
    const onClickScheduleHistoryButton = (memberName: string) => {
        setSearchMemberName(memberName);
        setIsMemberInfoModal(false);
        setIsClassHistoryModal(true);
        setIsClickedHistoryAtInfoModal(true);
    };

    // 해당 트레이너 정보 조회 API : (GET)
    const getTrainerInfoAtManage = useQuery(
        ['getTrainerInfoAtManageAPI', employeeId],
        async () => await getTrainerInfoAtManageAPI(employeeId, authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res.data.employeeProfile;

                    setCoachId(response?.coachId);
                    setCoachName(response?.name);
                    setCoachCode(response?.inviteCode);
                    setTotalMemberCount(response?.totalMemberCount);
                    setActiveMemberCount(response?.activeMemberCount);
                    setIsShareOnetimeSchedule(response?.isShareOnetimeSchedule);

                    setCoachInfo(response);

                    setApiData({
                        dateBirth: response?.birthday,
                        gender: response?.gender,
                        email: response?.email,
                        phoneNumber: response?.phoneNumber,
                        dateJoinCompany: response?.startDate,
                        position: response?.jobPosition,
                    });
                    setDateBirth(response?.birthday);
                    setEmail(response?.email);
                    setPhoneNumber(response?.phoneNumber);
                    setDateJoinCompany(response?.startDate);
                    setPosition(response?.jobPosition);
                    response?.gender === '' && setSelectedGender({ value: '', label: '선택' });
                    response?.gender === 'male' && setSelectedGender({ value: 'male', label: '남성' });
                    response?.gender === 'female' && setSelectedGender({ value: 'female', label: '여성' });
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!employeeId && !!!authObject?.testAccount,

            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    // 강사 프로필 더미데이터 세팅
    useEffect(() => {
        if (authObject?.testAccount && trainerProfileDummyData) {
            const response: any = trainerProfileDummyData.employeeProfile;
            setCoachId(response.coachId);
            setCoachName(response.name);
            setCoachCode(response.inviteCode);
            setTotalMemberCount(response.totalMemberCount);
            setActiveMemberCount(response.activeMemberCount);
            setIsShareOnetimeSchedule(response.isShareOnetimeSchedule);

            setApiData({
                dateBirth: response.birthday,
                gender: response.gender,
                email: response.email,
                phoneNumber: response.phoneNumber,
                dateJoinCompany: response.startDate,
                position: response.jobPosition,
            });
            setDateBirth(response.birthday);
            setEmail(response.email);
            setPhoneNumber(response.phoneNumber);
            setDateJoinCompany(response.startDate);
            setPosition(response.jobPosition);
            response.gender === '' && setSelectedGender({ value: '', label: '선택' });
            response.gender === 'male' && setSelectedGender({ value: 'male', label: '남성' });
            response.gender === 'female' && setSelectedGender({ value: 'female', label: '여성' });
        }
    }, [trainerProfileDummyData, authObject?.testAccount]);

    //  트레이너 프로필 수정 API : (PATCH)
    const modifyTrainerProfile = useMutation({
        mutationFn: async () =>
            await modifyTrainerProfileAPI(
                employeeId,
                dateBirth,
                selectedGender.value,
                email,
                phoneNumber,
                dateJoinCompany,
                position,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data.employeeProfile;

                setDateBirth(response.birthday);
                setEmail(response.email);
                setPhoneNumber(response.phoneNumber);
                setDateJoinCompany(response.startDate);
                setPosition(response.jobPosition);
                response.gender === '' && setSelectedGender({ value: '', label: '선택' });
                response.gender === 'male' && setSelectedGender({ value: 'male', label: '남성' });
                response.gender === 'female' && setSelectedGender({ value: 'female', label: '여성' });
                setApiData({
                    dateBirth: response.birthday,
                    gender: response.gender,
                    email: response.email,
                    phoneNumber: response.phoneNumber,
                    dateJoinCompany: response.startDate,
                    position: response.jobPosition,
                });
                createAlert('수정 완료', '트레이너 정보 수정을 완료하였습니다.');
            } else {
                createAlert('', '트레이너 정보 수정중 오류가 발생하였습니다.');
            }
        },
    });

    //  트레이너 삭제API : (PATCH)
    const deleteTrainer = useMutation({
        mutationFn: async () => await deleteTrainerAPI(authObject.centerId, employeeId, authorization),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data;
                const connectedCoachs = response.center.relations.filter(
                    (coach: any) => coach.isDeleted === false && coach.isConnected === true
                );

                const authObjectData = {
                    adminId: authObject?.adminId,
                    id: authObject?.id,
                    name: authObject?.name,
                    email: authObject?.email,
                    gender: authObject?.gender,
                    phoneNumber: authObject?.phoneNumber,
                    profileImageUrl: authObject?.profileImageUrl,
                    /** 센터 */
                    centerAddress: authObject?.centerAddress,
                    centerCategory: authObject?.centerCategory,
                    centerCode: authObject?.centerCode,
                    centerName: authObject?.centerName,
                    centerId: authObject?.centerId,
                    isNewCommunicationBox: authObject?.isNewCommunicationBox,

                    /** 구독플랜관련 */
                    coachCount: connectedCoachs.length,
                    memberCount: authObject?.memberCount,
                    activationDate: authObject?.activationDate,
                    expirationDate: authObject?.expirationDate,
                    expirationDelayDate: authObject?.expirationDelayDate,
                    finalPaymentDate: authObject?.finalPaymentDate,
                    finalPaymentTryDate: authObject?.finalPaymentTryDate,
                    cardIssuingBank: authObject?.cardIssuingBank,
                    cardNumber: authObject?.cardNumber,
                    planAmount: authObject?.planAmount,
                    usableStatus: authObject?.authObject,
                    paymentDueDate: authObject?.paymentDueDate,

                    /** 구독플랜 인원 정보 */
                    planLimitCount: authObject?.planLimitCount, // 플랜 제한 이용 인원
                    prevPlanLimitCount: authObject?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                    changedPlanLimitCount: authObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트
                    confirmUsableStatus: authObject?.confirmUsableStatus,
                    testAccount: authObject?.testAccount,
                };

                setItemWithExpiration('authObject', authObjectData, 12);
                setAuthObject(authObjectData);
                queryClient.invalidateQueries({ queryKey: ['getTrainerListAPI'] });

                createAlert('삭제 완료', '해당 트레이너 삭제를 완료하였습니다');
                navigate('/trainerManage');
            } else {
                setIsDeleteAlertModal(false);
                createAlert('', '트레이너 삭제중 오류가 발생하였습니다.');
            }
        },
    });

    // 트레이너 재등록API : (PATCH)
    const connectingTrainer = useMutation({
        mutationFn: async () => await connectingTrainerAPI(authObject.centerId, coachId, authorization),
        onSuccess: (res) => {
            console.log(res);
            if (res.status === 200) {
                setIsReRegisterModal(false);
                queryClient.invalidateQueries({ queryKey: ['getTrainerListAPI'] });
                createAlert(
                    '등록 완료',
                    `트레이너 등록이 완료되었습니다\n트레이너의 상세 정보는 '트레이너 정보'에서 상세 입력할 수 있습니다`
                );
                navigate('/trainerManage');
            }
            if (res.status === 404) {
                createAlert('', '트레이너를 찾을 수 없습니다!\n 코드를 다시 한번 확인해주세요', true);
            }
            if (res.status === 406) {
                createAlert(
                    '',
                    '현재 요금제에서는 더이상 추가가 불가능합니다.\n 문제가 있을 시 카카오톡 Rappo 채널로 문의 부탁드려요'
                );
            }
            if (res.status === 409) {
                createAlert('', '이미 등록된 트레이너 입니다');
            }
        },
    });

    // 수강권 권한 변경 : (PATCH)
    const updateTrainerPermission = useMutation({
        mutationFn: async () =>
            await updateTrainerPermissionAPI(
                authObject.centerId,
                coachId,
                !coachInfo?.isMembershipPermissionAll,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                queryClient.invalidateQueries({ queryKey: ['getTrainerInfoAtManageAPI'] });
            } else {
                createAlert('오류', '다시 시도해주세요 \n 계속 같은 오류가 발생한다면 문의바랍니다', true);
            }
        },
    });

    // 삭제하기 버튼클릭이벤트
    const onClickDeleteButton = () => {
        setIsDeleteAlertModal(true);
    };

    // 트레이너 삭제하기
    const onClickDeleteAccount = () => {
        deleteTrainer.mutate();
    };

    // 삭제된 트레이너 재등록하기
    const onClickReRegisterTrainer = () => {
        connectingTrainer.mutate();
    };

    // 재등록하기 버튼 클릭이벤트
    const onClickReRegisterButton = () => {
        setIsReRegisterModal(true);
    };

    // 수강권 관리 권한 ON/OFF
    const onClickPermissionChange = () => {
        updateTrainerPermission.mutate();
    };

    // 휴대전화 번호 '-'추가 정제로직
    useEffect(() => {
        if (phoneNumber) {
            if (phoneNumber.length === 10) {
                setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
            }
            if (phoneNumber.length === 13) {
                setPhoneNumber(phoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
            }
        }
    }, [phoneNumber]);

    // 생년월일 '-'추가 정제로직
    useEffect(() => {
        if (dateBirth) {
            if (dateBirth.length === 8) {
                setDateBirth(dateBirth.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
            }

            if (dateBirth.length === 9) {
                setDateBirth(dateBirth.replace(/-/g, ''));
            }
        }
    }, [dateBirth]);

    // 입사일 '-'추가 정제로직
    useEffect(() => {
        if (dateJoinCompany) {
            if (dateJoinCompany.length === 8) {
                setDateJoinCompany(dateJoinCompany.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
            }

            if (dateJoinCompany.length === 9) {
                setDateJoinCompany(dateJoinCompany.replace(/-/g, ''));
            }
        }
    }, [dateJoinCompany]);

    // 변경 플래그감지
    useEffect(() => {
        if (dateBirth === apiData.dateBirth) {
            setIsChangedDateBirth(false);
        } else {
            setIsChangedDateBirth(true);
        }

        if (selectedGender.value === apiData.gender) {
            setIsChangedGender(false);
        } else {
            setIsChangedGender(true);
        }

        if (email === apiData.email) {
            setIsChangedEmail(false);
        } else {
            setIsChangedEmail(true);
        }

        if (phoneNumber === apiData.phoneNumber) {
            setIsChangedPhoneNumber(false);
        } else {
            setIsChangedPhoneNumber(true);
        }

        if (dateJoinCompany === apiData.dateJoinCompany) {
            setIsChangedJoinDate(false);
        } else {
            setIsChangedJoinDate(true);
        }

        if (position === apiData.position) {
            setIsChangedPosition(false);
        } else {
            setIsChangedPosition(true);
        }
    }, [dateBirth, selectedGender, email, phoneNumber, dateJoinCompany, position]);

    // 접속 데이터 측정 API
    const pushUserFlow = useMutation({
        mutationFn: async () => await storeUserFlow('trainerInfo', authorization),
    });
    // 접속 데이터 측정 API
    useEffect(() => {
        try {
            if (pushUserFlow) {
                pushUserFlow.mutate();
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <Col style={{ alignItems: 'center', marginTop: 64 }} onClick={onClickOutSide}>
            <DetailHeader onClickBackButton={onClickBackButton} />

            <Row style={{ width: 1192, justifyContent: 'space-between', marginTop: 24 }}>
                <DetailTopLeft
                    coachName={coachName}
                    coachCode={coachCode}
                    isConnected={isConnected}
                    isDeleted={isDeleted}
                    coachInfo={coachInfo}
                    onClickPermissionChange={onClickPermissionChange}
                />
                <DetailTopRight
                    dateBirth={dateBirth}
                    selectedGender={selectedGender}
                    email={email}
                    phoneNumber={phoneNumber}
                    dateJoinCompany={dateJoinCompany}
                    position={position}
                    setSelectedGender={setSelectedGender}
                    onChangeDateBirth={onChangeDateBirth}
                    onChangeEmail={onChangeEmail}
                    onChangePhoneNumber={onChangePhoneNumber}
                    onChangeDateJoinCompany={onChangeDateJoinCompany}
                    onChangePosition={onChangePosition}
                    onClickMemberInfo={onClickMemberInfo}
                    onClickSave={onClickSave}
                    changeFlag={changeFlag}
                    onClickClassHistory={onClickClassHistory}
                    isConnected={isConnected}
                    isDeleted={isDeleted}
                    isShareOnetimeSchedule={isShareOnetimeSchedule}
                    authObject={authObject}
                    isOpenGender={isOpenGender}
                    setIsOpenGender={setIsOpenGender}
                    onClickDeleteButton={onClickDeleteButton}
                />
            </Row>

            <LargeToggleButton
                leftText="회원 관리"
                rightText="수업 기록"
                isLeft={pageType === 'manageMember'}
                isRight={pageType === 'classHistory'}
                callBackLeft={() => {
                    setPageType('manageMember');
                }}
                callBackRight={() => {
                    setPageType('classHistory');
                }}
                customStyle={{ marginTop: 128 }}
            />
            {pageType === 'manageMember' && (
                <ManageMember
                    authorization={authorization}
                    employeeId={employeeId}
                    authObject={authObject}
                    coachId={coachId}
                />
            )}
            {pageType === 'classHistory' && (
                <ClassHistory authorization={authorization} employeeId={employeeId} authObject={authObject} />
            )}

            {/* 담당 회원정보 모달 */}
            {/* {isMemberInfoModal && (
                <ModalMemberInfo
                    modalVisible={isMemberInfoModal}
                    setModalVisible={setIsMemberInfoModal}
                    authorization={authorization}
                    employeeId={employeeId}
                    onClickScheduleHistoryButton={onClickScheduleHistoryButton}
                    authObject={authObject}
                />
            )} */}

            {/* 수업 히스토리 모달 */}
            {isClassHistoryModal && !isMemberInfoModal && (
                <ModalClassHistory
                    modalVisible={isClassHistoryModal}
                    setModalVisible={setIsClassHistoryModal}
                    authorization={authorization}
                    employeeId={employeeId}
                    searchMemberName={searchMemberName}
                    setSearchMemberName={setSearchMemberName}
                    authObject={authObject}
                    isClickedHistoryAtInfoModal={isClickedHistoryAtInfoModal}
                    setIsClickedHistoryAtInfoModal={setIsClickedHistoryAtInfoModal}
                    onClickBackMemberInfo={onClickBackMemberInfo}
                />
            )}

            {/* 트레이너 삭제모달 */}
            {isDeleteAlertModal && (
                // <ModalAlert
                //     modalVisible={isDeleteAlertModal}
                //     setModalVisible={setIsDeleteAlertModal}
                //     title={''}
                //     content={'트레이너의 정보를 삭제하시겠습니까?\n삭제해도 지난 정보는 확인할 수 있습니다'}
                //     callBack={onClickDeleteAccount}
                //     isError={true}
                // />
                <ModalCoachDelete
                    setIsVisible={setIsDeleteAlertModal}
                    onClickDelete={onClickDeleteAccount}
                    isDisableDelete={totalMemberCount === 0}
                />
            )}

            {/* 재등록하기 버튼 클릭시 모달 */}
            {isReRegisterModal && (
                <ModalReRegister
                    modalVisible={isReRegisterModal}
                    setModalVisible={setIsReRegisterModal}
                    coachName={coachName}
                    coachCode={coachCode}
                    onClickReRegisterTrainer={onClickReRegisterTrainer}
                />
            )}
        </Col>
    );
};

export default TrainerInformationPage;
