import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { useLocation } from 'react-router-dom';
import MemberProfileComponents from '../components/MemberProfile/MemberProfileComponents';
import MemberMemberShipComponents from '../components/MemberProfile/MemberMemberShipComponents';
import ModalRegisterPass from './ClassPass/ModalRegisterPass/ModalRegisterPass';
import dayjs from 'dayjs';
import { CodeValueType } from '../types/types';
import MemberClassHistoryComponents from '../components/MemberProfile/MemberClassHistoryComponents';
import { getItemWithExpiration } from '../lib/utils';
import useMultipleQueries from '../hook/useMultipleQueries';
import { fetchCoachNameList } from '../services/useCenterQueryService';
import { fetchOneMemberProfile, MemberProfile } from '../services/useMemberQueryService';
import { isEmpty } from 'lodash';
import { fetchAllMembershipForMember, MemberMembershipDataType } from '../services/useMembershipQueryService';
import useSingleQuery from '../hook/useSingleQuery';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    createAlert: any;
};

const MemberProfilePage = React.memo(({ createAlert }: Props) => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const { memberId } = location.state || {};

    const [isRegisterPassModal, setIsRegisterPassModal] = useState(false);

    const { refinedResults, isLoading } = useMultipleQueries([
        {
            fetchFn: fetchCoachNameList,
            params: { centerId: authObject?.centerId },
            enabled: !!authObject?.centerId,
        },
        {
            fetchFn: fetchOneMemberProfile,
            params: { centerId: authObject?.centerId, memberId: memberId },
            enabled: !!authObject?.centerId && !!memberId,
        },
    ]);

    const dropDownCoachList = useMemo(
        () => (refinedResults?.[0]?.data as CodeValueType<string>[]) ?? [],
        [refinedResults]
    );

    const memberProfile = useMemo(() => (refinedResults?.[1]?.data as MemberProfile) ?? undefined, [refinedResults]);

    const { refinedResult: asyncResult, isLoading: _resultLoading } = useSingleQuery({
        fetchFn: fetchAllMembershipForMember,
        params: {
            coachId: memberProfile?.coachId,
            memberId: memberId,
            accessToken: authObject?.accessToken,
        },
        enabled: !isEmpty(memberProfile) && !!memberProfile?.coachId,
    });

    const asyncMembershipListData = useMemo(
        () => (asyncResult?.data as MemberMembershipDataType[]) ?? [],
        [asyncResult]
    );

    const [name, setName] = useState<string>(memberProfile ? memberProfile.name : '');
    const [selectCoach, setSelectCoach] = useState<CodeValueType | undefined>(
        dropDownCoachList && dropDownCoachList.find((item: CodeValueType) => item.code === memberProfile?.coachId)
    );

    const [birthDay, setBirthDay] = useState<string>(
        memberProfile ? dayjs(memberProfile.birthday).format('YYYY-MM-DD') : ''
    );
    const [selectGender, setSelectGender] = useState<CodeValueType | undefined>({
        code: memberProfile?.gender || '',
        value: memberProfile?.gender === 'male' ? '남성' : '여성',
    });
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(memberProfile?.phoneNumber);
    const [memo, setMemo] = useState<string>(memberProfile ? memberProfile.memo : '');

    const [isChange, setIsChange] = useState<boolean>(false);

    useEffect(() => {
        if (
            memberProfile &&
            (name !== memberProfile.name ||
                selectCoach?.code !== memberProfile.coachId ||
                !dayjs(birthDay).isSame(memberProfile.birthday, 'day') ||
                selectGender?.code !== memberProfile.gender ||
                (phoneNumber ?? '') !== (memberProfile?.phoneNumber ?? '') ||
                memo !== memberProfile?.memo)
        ) {
            setIsChange(true);
        } else {
            setIsChange(false);
        }
    }, [name, selectCoach, birthDay, selectGender, phoneNumber, memo, memberProfile]);

    const handleRegisterPassModal = useCallback(() => {
        setIsRegisterPassModal(true);
    }, []);

    return isLoading && _resultLoading ? null : (
        <div className="flex flex-col mt-[112px] justify-center ">
            <MemberProfileComponents
                memberProfile={memberProfile}
                memberId={memberId}
                dropDownCoachList={dropDownCoachList}
                isChange={isChange}
                setName={setName}
                setSelectCoach={setSelectCoach}
                setBirthDay={setBirthDay}
                setSelectGender={setSelectGender}
                setPhoneNumber={setPhoneNumber}
                setMemo={setMemo}
                name={name}
                selectCoach={selectCoach}
                birthDay={birthDay}
                selectGender={selectGender}
                phoneNumber={phoneNumber}
                memo={memo}
                createAlert={createAlert}
            />
            <MemberMemberShipComponents
                memberId={memberId}
                coachId={memberProfile?.coachId}
                coachName={selectCoach?.value}
                memberName={name}
                onClickCreateNewMembership={handleRegisterPassModal}
                allMembershipListData={asyncMembershipListData}
                createAlert={createAlert}
            />
            <MemberClassHistoryComponents coachId={memberProfile?.coachId} memberId={memberId} />
            {isRegisterPassModal && (
                <ModalRegisterPass
                    modalVisible={isRegisterPassModal}
                    setModalVisible={setIsRegisterPassModal}
                    createAlert={createAlert}
                    onSuccessCreate={() => {
                        queryClient.invalidateQueries({
                            queryKey: ['getMembershipTemplatesListAPIQuery'],
                        });
                    }}
                />
            )}
        </div>
    );
});

export default memo(MemberProfilePage);
