import React, { Dispatch, SetStateAction } from 'react';
import styled, { keyframes } from 'styled-components';
import { HoverImage } from '../../lib/styled';
import { I_GRADIENT_SCHEDULE, I_POPUP_EXIT } from '../../types/images';
import { Col, Row } from '../../lib/utils';
import { Body1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import WhiteSquareButton from '../Button/WhiteSquareButton';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalInner = styled.div`
    background: white;
    width: 552px;
    height: 408px;
    right: 0;
    border-radius: 8px;
    box-shadow: 8px 8px 24px 0px #00000052;

    background: #f5f9ff;
    display: flex;
    flex-direction: column;
`;
type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    navigate: any;
    createAlert: any;
    selectedSchedule: any;
    setSelectedSchedule: any;
    calendarType: 'daily' | 'weekly';
    calendarSelectedDay: string;
    calendarScrollY: number;
};

const ModalCreateClass = ({
    modalVisible,
    setModalVisible,
    navigate,
    createAlert,
    selectedSchedule,
    setSelectedSchedule,
    calendarType,
    calendarSelectedDay,
    calendarScrollY,
}: Props) => {
    return (
        <ModalOverLay>
            <ModalInner>
                <Row>
                    <Col style={{ width: 138, height: 80, marginLeft: 207, marginTop: 32, alignItems: 'center' }}>
                        <img src={I_GRADIENT_SCHEDULE} style={{ width: 48, height: 48 }} />
                        <Body1Bold style={{ color: colors.LAVEL_4 }}>{'새로운 일정 생성'}</Body1Bold>
                    </Col>
                    <HoverImage
                        src={I_POPUP_EXIT}
                        style={{ width: 24, height: 24, marginLeft: 151, marginTop: 16 }}
                        onClick={() => {
                            setModalVisible(false);
                            setSelectedSchedule(null);
                        }}
                    />
                </Row>

                <Col style={{ width: 504, alignSelf: 'center', marginTop: 32 }}>
                    <Row style={{ width: 504, justifyContent: 'space-between' }}>
                        <WhiteSquareButton
                            customStyle={{ width: 240, height: 64 }}
                            text="수업 일정"
                            size="big"
                            callBack={() => {
                                navigate('/schedule/create', {
                                    state: {
                                        type: 'oneSchedule',
                                        selectedSchedule: selectedSchedule,
                                        calendarType,
                                        calendarSelectedDay,
                                        calendarScrollY,
                                    },
                                });
                            }}
                        />
                        <WhiteSquareButton
                            customStyle={{ width: 240, height: 64 }}
                            text="반복수업 일정"
                            size="big"
                            callBack={() => {
                                navigate('/schedule/create', {
                                    state: {
                                        type: 'repeatSchedule',
                                        selectedSchedule: selectedSchedule,
                                        calendarType,
                                        calendarSelectedDay,
                                        calendarScrollY,
                                    },
                                });
                            }}
                        />
                    </Row>

                    <Row style={{ width: 504, justifyContent: 'space-between', marginTop: 24 }}>
                        <WhiteSquareButton
                            customStyle={{ width: 240, height: 64 }}
                            text="일반 일정"
                            size="big"
                            callBack={() => {
                                navigate('/schedule/create', {
                                    state: {
                                        type: 'etcSchedule',
                                        selectedSchedule: selectedSchedule,
                                        calendarType,
                                        calendarSelectedDay,
                                        calendarScrollY,
                                    },
                                });
                            }}
                        />
                        <WhiteSquareButton
                            customStyle={{ width: 240, height: 64 }}
                            text="OFF 일정"
                            size="big"
                            callBack={() => {
                                navigate('/schedule/create', {
                                    state: {
                                        type: 'offSchedule',
                                        selectedSchedule: selectedSchedule,
                                        calendarType,
                                        calendarSelectedDay,
                                        calendarScrollY,
                                    },
                                });
                            }}
                        />
                    </Row>

                    <WhiteSquareButton
                        customStyle={{ width: 504, height: 64, marginTop: 24 }}
                        text="그룹 수업"
                        size="big"
                        callBack={() => {
                            createAlert(
                                '조금만 기다려주세요! 🥲',
                                '열심히 개발중입니다!\n최대한 빠른 시일 내에 이용하실 수 있게 노력할게요!',
                                true
                            );
                        }}
                    />
                </Col>
            </ModalInner>
        </ModalOverLay>
    );
};

export default ModalCreateClass;
