import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
interface InputButtonProps {
    toggled: string;
}
const InputButton = styled.div`
    width: 64px;
    height: 32px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 2.5px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 1px 1px 2px 0px #00000040 inset;
`;

interface CircleProps {
    toggled: string;
}

const Circle = styled.div<CircleProps>`
    width: 23px;
    height: 23px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
    transform: ${({ toggled }) => (toggled === 'true' ? 'translateX(2px)' : 'translateX(34px)')};
`;

type Props = {
    isActive: boolean;
    callBack: () => void;
    disableColor?: string;
};
const NormalToggleButton = ({ isActive, callBack, disableColor }: Props) => {
    const [isToggled, setIsToggled] = useState(isActive);

    useEffect(() => {
        setIsToggled(isActive);
    }, [isActive]);

    return (
        <Container>
            <InputButton
                style={{
                    backgroundColor: isToggled ? '#00A793' : disableColor ? disableColor : '#C7C7C7',
                }}
                onClick={callBack}
            >
                <Circle toggled={isToggled ? 'true' : 'false'} />
            </InputButton>
        </Container>
    );
};

export default NormalToggleButton;
