import React from 'react';
import { colors } from '../../../lib/colors';
import { Col, Row } from '../../../lib/utils';
import ProfileImage from '../../ProfileImage';
import { Body3Regular, CaptionRegular } from '../../../lib/font';
import styled from 'styled-components';

const StyledButton = styled.button<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 378px;
    height: 56px;
    align-items: center;
    align-self: center;
    background-color: ${({ selected }) => (selected ? '#E9F1FF' : '#FDFEFF')};
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #e9f1ff;
    padding-left: 16px;
    padding-right: 24px;
    z-index: 1;

    &:hover {
        background-color: #f5f9ff;
    }
`;

const CoachButton = ({ coachId, coachItem, onClickCoach, memberList }: any) => {
    const totalMemberCnt = memberList?.length;
    const activeMemberList = memberList.filter((item: any) => item.status === 'active');

    return (
        <StyledButton onClick={() => onClickCoach(coachItem.coachId)} selected={coachId === coachItem.coachId}>
            <Row style={{ alignItems: 'center' }}>
                <ProfileImage
                    imageUrl={coachItem?.profileImageUrl}
                    type="Small"
                    profileName={coachItem?.profileName}
                    profileColorType={coachItem?.profileColorType}
                    right={8}
                />
                <Col style={{ alignItems: 'flex-start' }}>
                    <Body3Regular style={{ color: colors.BLUE_700 }}>{`${coachItem?.coachName} 강사`}</Body3Regular>
                    <Row style={{ alignItems: 'center' }}>
                        <CaptionRegular>{`총 회원 : ${totalMemberCnt}명`}</CaptionRegular>
                        <div
                            style={{
                                width: 2,
                                height: 2,
                                borderRadius: 1,
                                backgroundColor: colors.LAVEL_3,
                                marginLeft: 6,
                                marginRight: 6,
                            }}
                        />
                        <CaptionRegular>{`활성 회원 : ${
                            activeMemberList ? activeMemberList.length : 0
                        }명`}</CaptionRegular>
                        <div
                            style={{
                                width: 2,
                                height: 2,
                                borderRadius: 1,
                                backgroundColor: colors.LAVEL_3,
                                marginLeft: 6,
                                marginRight: 6,
                            }}
                        />
                        <CaptionRegular>{`일지 작성 : ${coachItem.coachScheduleReportCount}개`}</CaptionRegular>
                    </Row>
                </Col>
            </Row>

            <div
                style={{
                    width: 16,
                    height: 16,
                    borderRadius: 12,
                    border: '1px solid #A6BFEF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {coachId === coachItem.coachId && (
                    <div
                        style={{
                            width: 10,
                            height: 10,
                            borderRadius: 9,
                            backgroundColor: colors.primary,
                        }}
                    />
                )}
            </div>
        </StyledButton>
    );
};

export default CoachButton;
