// apiService.js

import { Issuer } from '../api/center';
import { getAllMembershipForMember, getMembershipForMember } from '../api/memberShip';

export const fetchAllMembershipForMember = (
    params: {
        coachId: string;
        memberId: string;
        accessToken: string;
    },
    authorization: string
) => ({
    queryKey: ['getAllMembershipForMemberQuery', params.coachId, params.memberId],
    queryFn: () => getAllMembershipForMember(params.coachId, params.memberId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.membersMemberships ?? [];
    },
});
export const fetchMembershipForMember = (
    params: {
        membershipId: string;
    },
    authorization: string
) => ({
    queryKey: ['getMembershipForMemberQuery', params.membershipId],
    queryFn: () => getMembershipForMember(params.membershipId, authorization),
    onSuccess: (data: any) => {
        // 데이터 정제 로직
        return data?.data?.membership ?? [];
    },
});

type WeightRecord = {
    value: number;
    recordDate: string; // ISO 날짜 문자열로 정의
};

export type MemberProfile = {
    inviteCode: string;
    isInfo: boolean;
    isRequest: boolean;
    name: string; // 회원 이름
    email: string;
    profileImageUrl: string; // 프로필 이미지 url
    weight: WeightRecord[];
    created: string; // ISO 날짜 문자열로 정의
    createdAt: string; // 등록일
    updatedAt: string;
    __v: number;
    birthday: string; // 회원 생년월일 (ISO 날짜 문자열)
    exerciseVolume: string; // Enum으로 정의할 수 있지만, 현재는 string으로 정의
    gender: 'male' | 'female'; // 회원 성별
    height: number;
    isDeleted: boolean;
    phoneNumber: string; // 회원 휴대폰
    id: string; // memberId
    profileColorType: string; // 기본 프로필 색상
    profileName: string; // 기본 프로필 이름
    coachProfileImageUrl: string;
    coachProfileColorType: string;
    coachProfileName: string;
    coachId: string; // 담당강사 coachId
    coachName: string; // 담당강사 이름
    memo: string; // 메모
};

/**
 * 회원권
 * */
export type MemberMembershipDataType = {
    _id: string;
    period: number;
    isDeleted: boolean;
    isCanceled: boolean;
    coachId: string;
    memberId: string;
    name: string;
    activationDate: string;
    expirationDate: string;
    startSessionValue: number;
    totalSession: number;
    createdAt: string;
    updatedAt: string;
    __v: number;
    BGColor: string[];
    issuer: Issuer;
    membershipTemplateId: string;
    memo: string;
    pricePerSchedule: number;
    status: 'expired' | 'active';
    totalPrice: number;
    currentSession: number;
    schedules: MemberScheduleDataType[];
    remainSession: number;
};

/** 회원권의 스케줄 */
export type MemberScheduleDataType = {
    __v: number;
    _id: string;
    alias: string;
    attendanceStatus: 'ATTENDANCE' | 'NOSHOW';
    coachId: string;
    conditionId: string;
    createdAt: string;
    currentSession: number;
    endTime: string;
    isCanceled: boolean;
    isDeleted: boolean;
    memberId: string;
    membershipId: string;
    membership: MemberMembershipDataType;
    memo: string;
    postId: string;
    requestDeadline: string;
    scheduleBundleId: string | null;
    startTime: string;
    totalSession: number;
    updatedAt: string;
};
