import React, { memo, useState } from 'react';
import { Subtitle1Bold } from '../../lib/font';
import { Col, Row } from '../../lib/utils';
import { colors } from '../../lib/colors';
import MembershipCard from './MembershipCard';
import NewColorRoundButton from '../Button/NewColorRoundButton';
import ModalMembershipCreate from './ModalMembershipCreate';
import { useLocation, useNavigate } from 'react-router-dom';
import { MemberMembershipDataType } from '../../services/useMembershipQueryService';

const MemberMemberShipComponents = ({
    memberId,
    coachId,
    coachName,
    memberName,
    onClickCreateNewMembership,
    allMembershipListData,
    createAlert,
}: {
    memberId: string;
    coachId: string;
    coachName?: string;
    memberName?: string;
    onClickCreateNewMembership: () => void;
    allMembershipListData: MemberMembershipDataType[];
    createAlert: any;
}) => {
    // 수강권 등록 모달 열기
    const [isModalMembershipCreateVisible, setIsModalMembershipCreateVisible] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="flex flex-col w-full h-full justify-center items-center mb-[80px]">
            <Row className="w-[1192px] items-center mb-[24px]">
                <Subtitle1Bold style={{ color: colors.LAVEL_4 }}>수강권 정보</Subtitle1Bold>
            </Row>
            {/* <MembershipCard/> */}
            <Row className="flex flex-row w-[1192px] justify-start items-start overflow-x-scroll">
                {allMembershipListData?.map((membership: MemberMembershipDataType, index: number) => (
                    <MembershipCard
                        index={index}
                        membership={membership}
                        onClickDetailPage={() =>
                            navigate('/member/detail/membership', {
                                state: {
                                    membershipId: membership._id,
                                    memberName: memberName,
                                    coachName: coachName,
                                },
                            })
                        }
                    />
                ))}
                <Col
                    className="flex flex-col w-[310px] h-[160px] border-BLUE_200 border-spacing-50 border-dashed border-[1px] bg-BLUE_50 justify-center items-center rounded-[8px] flex-shrink-0 cursor-pointer"
                    onClick={() => setIsModalMembershipCreateVisible(true)}
                >
                    <NewColorRoundButton
                        text="수강권 등록하기"
                        fontType={'CaptionRegular'}
                        width={112}
                        height={24}
                        bgColor="primary"
                        textColor="white"
                    />
                </Col>
            </Row>
            {isModalMembershipCreateVisible && (
                <ModalMembershipCreate
                    setIsVisible={setIsModalMembershipCreateVisible}
                    onClickCreateNewMembership={onClickCreateNewMembership}
                    memberName={memberName}
                    coachName={coachName}
                    coachId={coachId}
                    memberId={memberId}
                    createAlert={createAlert}
                />
            )}
        </div>
    );
};

export default MemberMemberShipComponents;
