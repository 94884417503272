import { I_DEPRESSED, I_GOOD, I_SOSO, I_TERRIBLE, I_VERY_GOOD } from '../types/images';

export const PAINSTEP = [
    '아주 약간 이상한 느낌만 있는 정도',
    '크게 움직일 때 저림, 땡김이 있는 정도',
    '움직일 때 욱신거림, 열감이 느껴지는 정도',
    '움직이지 않을때도 통증이 느껴지는 정도',
    '통증 때문에 운동에 크게 지장이 있을 정도',
];

export const PAINTYPE = [
    { code: 'TINGLING', value: '따끔함' },
    { code: 'SENSITIVITY', value: '저림' },
    { code: 'CHILLS', value: '시림' },
    { code: 'STIFFNESS', value: '뻐근함' },
    { code: 'ETC', value: '기타' },
];

export const PAINPERIOD = [
    { code: 'DDAY', value: '당일' },
    { code: 'WITHIN_TEN_DAYS', value: '열흘 내' },
    { code: 'OVER_ONE_MONTH', value: '1개월 이상' },
    { code: 'OVER_THREE_MONTH', value: '3개월 이상' },
];

export const MENSTRUATIONAMOUNT = [
    { code: 'NONE', value: '없음' },
    { code: 'HEAVY', value: '많음' },
    { code: 'LIGHT', value: '적음' },
];

export const PMSPAINTYPE = [
    { code: 'LOWER_ABDOMEN_PAIN', value: '하복부 통증' },
    { code: 'ABDOMINAL_DISTENSION', value: '복부 팽만' },
    { code: 'HEADACHE', value: '두통' },
    { code: 'CHEST_PAIN', value: '가슴 통증' },
    { code: 'MUSCLE_PAIN', value: '근육통' },
    { code: 'PELVIC_PAIN', value: '골반 통증' },
];

export const PMSPAINSTEP = [
    '평소와 다른 민감함을 감지한 정도',
    '큰 움직임에 불편함이 생기는 정도',
    '가벼운(작은) 동작에도 불편함이 생기는 정도',
    '움직일 순 있지만 가만히 있어도 불편한 정도',
    '통증때문에 운동에 크게 지장이 있을 정도',
];

// EMOTIONAL_FLUCTUATIONS // 감정기복
// APPETITE_CHANGES // 식욕변화
// CONSTIPATION // 변비
// DIARRHEA // 설사
// COLD_SWEET // 식은땀
// FEVER_AND_CHILLS // 발열 & 오한
// FATIGUE // 피로함
// NAUSEA_AND_VOMITING // 매스꺼움&구토
export const PMSTYPE = [
    { code: 'EMOTIONAL_FLUCTUATIONS', value: '감정기복' },
    { code: 'APPETITE_CHANGES', value: '식욕변화' },
    { code: 'CONSTIPATION', value: '변비' },
    { code: 'DIARRHEA', value: '설사' },
    { code: 'COLD_SWEET', value: '식은땀' },
    { code: 'FEVER_AND_CHILLS', value: '발열 & 오한' },
    { code: 'FATIGUE', value: '피로함' },
    { code: 'NAUSEA_AND_VOMITING', value: '매스꺼움&구토' },
];

export const CONDITION_TYPE = [
    { code: 'TOP', src: I_VERY_GOOD, text: '최상' },
    { code: 'GOOD', src: I_GOOD, text: '좋음' },
    { code: 'NORMAL', src: I_SOSO, text: '보통' },
    { code: 'SAD', src: I_DEPRESSED, text: '우울한' },
    { code: 'BAD', src: I_TERRIBLE, text: '불쾌한' },
];
